
import lottie from 'lottie-web'

const paths = {
    circle: '/assets/lottie/circle.json',
    play: '/assets/lottie/play.json',
    carousel: '/assets/lottie/carousel.json',
    arrow: '/assets/lottie/arrow.json'
  }, lottieCache = {}

const getLottie = (id) => {
  if (lottieCache[id]) return lottieCache[id]

  return (lottieCache[id] = new Promise(resolve => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', paths[id])
    xhr.responseType = 'json'
    xhr.onload = () => {
      resolve(xhr.response)
    }

    xhr.send()
  }))
}

export default class LottieRoll {
  constructor (el) {
    this.el = el
    this.container = el.getAttribute('data-lottie') ? el : el.querySelector('[data-lottie]')
    if (!this.container) return

    this.id = this.container.getAttribute('data-lottie')
    this.speed = +this.container.getAttribute('data-speed') || 1
    getLottie(this.id).then(this.lottieReady)
    this.toggleEvents(true)
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.el[method]('mouseenter', this.mouseover)
    this.el[method]('mouseleave', this.mouseout)
  }

  lottieReady = (data) => {
    this.lottie = lottie.loadAnimation({
      container: this.container, // the dom element
      renderer: 'svg',
      loop: false,
      autoplay: false,
      className: 'lottie-svg',
      animationData: data, // the animation data
      rendererSettings: {
        preserveAspectRatio: this.id === 'circle' ? 'none' : undefined

      }
    })

    const svg = this.lottie.renderer.svgElement
    svg.style.pointerEvents = 'none'
    svg.style.width = ''
    svg.style.height = ''
  }

  mouseover = () => {
    if (!this.lottie) return
    this.lottie.setSpeed(this.speed)
    this.lottie.goToAndPlay(0)
  }

  mouseout=() => {
    // if (!this.lottie) return
    // this.lottie.setSpeed(-this.speed)
    // this.lottie.play()
  }

  resize () {
  }

  flush () {
    this.toggleEvents(false)
  }
}
