import {
  Color, MeshBasicMaterial, ShaderMaterial, UniformsLib, Vector3
} from 'three'
import { sample } from 'lodash-es'

import style from 'core/style'
import brushlineFragment from 'canvas/shaders/brushline.frag'
import brushlineVertex from 'canvas/shaders/brushline.vert'
import { replaceChunks } from 'canvas/helpers/ShaderHelper'
import detect from 'helpers/detect'

import textures from './textures'

let resolution

const setMaterialResolution = r => (resolution = r)

const getChannel = (channel) => {
  return channel === 'r' ? new Vector3(1, 0, 0)
    : channel === 'g' ? new Vector3(0, 1, 0)
      : new Vector3(0, 0, 1)
}

const brushMaterial = (textureInfo, color = style.red) => {
  const wireframe = !!0

  if (!textureInfo) textureInfo = sample(textures.brushes)

  const material = new ShaderMaterial({
    wireframe,
    depthTest: false,
    depthWrite: false,
    transparent: true,
    fog: false,
    fragmentShader: replaceChunks(brushlineFragment),
    vertexShader: replaceChunks(brushlineVertex)
  })

  const { image } = textureInfo.texture

  const ratio = (image.naturalHeight ? (image.naturalWidth / image.naturalHeight) : (image.width / image.height))
  // const multiplier = 1
  const multiplier = (detect.mobile ? 1.7 : 1)

  material.uniforms = Object.assign({}, UniformsLib.fog, material.uniforms, {
    channel: { value: getChannel(textureInfo.channel) },
    map: { value: textureInfo.texture },
    useMap: { value: wireframe ? 0 : 1 },
    opacity: { value: 1 },
    color: { value: new Color(parseInt(color.replace('#', '0x'), 16)) },
    progress: { value: 1 },
    lineWidth: { value: textureInfo.lineWidth * multiplier },
    resolution: { value: resolution },
    lineLength: { value: .1 },
    noise: { value: 0 },
    noiseMultiplier: { value: 1 },
    sizeAttenuation: { value: 1 },
    textureRatio: { value: ratio * 2 * multiplier },
    textureClamp: { value: textureInfo.clamp }
  })

  return material
}

const basicMaterial = () => new MeshBasicMaterial({
  depthTest: false,
  depthWrite: false,
  transparent: true
})

export {
  setMaterialResolution,
  brushMaterial,
  basicMaterial
}
