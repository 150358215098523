
import raf from '@internet/raf'
import { toPairs, now, reduce, sortBy } from 'lodash-es'
import anime from 'animejs'
import Inrtia from 'inrtia'
import Emitter from 'tiny-emitter'

import scroll from 'core/scroll'
import router from 'core/router'
import detect from 'helpers/detect'
import math from 'helpers/math'
import browser from 'helpers/browser'

const SPEED = 1.2

class Carousel extends Emitter {
  constructor (container, items) {
    super()
    this.el = container
    this.items = Array.from(items || container.children)
    this.step = { current: 0 }
    this.total = this.items.length

    this.resize()

    this.inrtia = new Inrtia({
      value: this.step.current,
      precision: 0.1,
      perfectStop: true,
      friction: !detect.touch ? 10 : 3
    })

    this.selectStep(0)
    this.updatePosition(true)
    this.toggleEvents(true)
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.el[method](!detect.touch ? 'mousedown' : 'touchstart', this.mousedown)
    window[method](!detect.touch ? 'mouseup' : 'touchend', this.mouseup)
    window[method](!detect.touch ? 'mousemove' : 'touchmove', this.mousemove, { passive: false })
    raf[add ? 'add' : 'remove'](this.updatePosition)
  }

  enable () {
    this._enable = true
    this.resize()
  }

  disable () {
    this._enable = false
  }

  prev = (force = false) => {
    const method = force ? math.wrap : math.clamp
    const step = method(this.step.current - this.offset, 0, this.end)
    // this.inrtia.to(this.steps[step])
    this.animateTo(step)
  }

  next = (force = false) => {
    const method = force ? math.wrap : math.clamp
    const step = method(this.step.current + this.offset, 0, this.end)
    // this.inrtia.to(this.steps[step])
    this.animateTo(step)
  }

  goTo = (rawStep, force = false) => {
    if (rawStep === this.step.current && force === false) return
    const step = this.steps[+rawStep]
    this.selectStep(+rawStep)
    this.inrtia.stop()
    this.inrtia.value = step
    this.inrtia.targetValue = step
    this.updatePosition(true)
  }

  animateTo = (rawStep) => {
    if (rawStep === this.step.current) return
    const step = this.steps[rawStep]

    this.inrtia.stop()
    anime.remove(this.inrtia)
    this.selectStep(rawStep)

    const value = this.inrtia.value

    anime({
      targets: this.inrtia,
      value: [value, step],
      targetValue: [value, step],
      easing: 'easeOutQuad',
      duration: 450,
      complete: () => this.updatePosition(true),
      change: () => this.updatePosition(true)
    })
  }

  mousedown = (event) => {
    if (this._enable === false) return

    event = browser.mouseEvent(event)

    this.firstFrame = {
      time: now(),
      x: event.pageX,
      y: (event.pageY - scroll.scrollTop()),
      value: this.inrtia.value
    }

    this.el.classList.add('grabbing')

    this.inrtia.stop()
  }

  hasClicked (event) {
    const distanceX = event.pageX - this.firstFrame.x
    const distanceY = (event.pageY - scroll.scrollTop()) - this.firstFrame.y
    const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)
    const delay = now() - this.firstFrame.time
    return (delay < 300) && (Math.abs(distance) < 30)
  }

  hasScrolled (event) {
    const distanceX = event.pageX - this.firstFrame.x
    const distanceY = (event.pageY - scroll.scrollTop()) - this.firstFrame.y
    const angle = Math.atan2(distanceY, distanceX) * (180 / Math.PI)

    const range = 30
    const gap = Math.abs(90 - Math.abs(angle))
    const horizontal = gap >= 90 - range

    // const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)
    return horizontal
  }

  mouseup = (event) => {
    if (this._enable === false || !this.firstFrame) return

    event && event.preventDefault()
    event && event.stopImmediatePropagation()
    event = browser.mouseEvent(event)

    // if (this.hasClicked(event)) this.click(event, event.target)

    const distance = event.pageX - this.firstFrame.x
    const value = this.firstFrame.value + (distance * SPEED)

    const clampedValue = Math.min(this.minStep, Math.max(this.maxStep, value))
    const newValue = this.findStep(clampedValue, true)

    this.inrtia.to(newValue)
    this.firstFrame = false
    this.el.classList.remove('grabbing')
  }

  click (event, target) {
    const link = target.querySelector('a[data-navigo]')
    if (!link) return
    const href = router.getLinkPath(link)
    router.navigate(href)
  }

  reset () {
    if (!this.inrtia) return
    const newValue = this.steps[this.step.current]
    if (newValue === this.inrtia.targetValue) return
    this.inrtia.to(newValue)
  }

  mousemove = (event) => {
    if (this._enable === false || !this.firstFrame) return

    const mouseEvent = browser.mouseEvent(event)
    const distance = mouseEvent.pageX - this.firstFrame.x

    // if (this.hasScrolled(mouseEvent)) event.preventDefault()
    // else return

    if (this.prevent) event.preventDefault()
    event && event.stopImmediatePropagation()

    const value = this.firstFrame.value + (distance * SPEED)
    const clampedValue = Math.min(this.minStep, Math.max(this.maxStep, value))

    this.inrtia.to(clampedValue)
  }

  findStep (value, select = false) {
    const sorted = sortBy(toPairs(this.steps), (step, i) => Math.abs(value - step[1]))
    const index = parseInt(sorted[0][0])

    if (select) this.selectStep(index)
    return this.steps[index]
  }

  updatePosition = (force) => {
    if (this._enable === false) return
    if (this.inrtia.stopped && force !== true) return
    const value = this.inrtia.update()
    this.el.style.transform = `translate3d(${value}px, 0, 0)`
    // this.el.style.transform = `translateX(${value}px)`
  }

  selectStep (step) {
    if (step === this.step.current) return
    this.step.current = step
    this.emit('update', step)
  }

  resize () {
    // const outer = this.el.parentNode
    // const outerWidth = outer.offsetWidth
    // const innerWidth = this.el.offsetWidth
    // const outerPadding = this.el.offsetLeft
    // const innerPadding = this.items[0].offsetLeft

    // let max = 0

    // if (outerWidth - (innerPadding * 2) < innerWidth) {
    //   const difference = (innerWidth + innerPadding) - (outerWidth - (outerPadding * 2))
    //   max = -Math.max(0, difference)
    // }

    this.end = this.items.length

    this.steps = reduce(this.items, (memo, li, i) => {
      let w = 0
      const s = window.getComputedStyle(li)
      w = -(li.offsetWidth + parseInt(s.marginLeft) + parseInt(s.marginRight))
      w += memo[i]

      // if (w <= max) this.end = Math.min((i + 1), this.end)
      // memo.push(Math.max(max, w))
      if (i < this.end - 1)
        memo.push(w)
      return memo
    }, [0])

    this.minStep = this.steps[0]
    this.maxStep = this.steps[this.steps.length - 1]
    this.stepWidth = Math.abs(this.steps[1] - this.steps[0])
    this.offset = Math.max(1, (this.items.length - this.end) + 1)

    const lastAttribute = this.el.getAttribute('style', '')
    if (this._enable === false) this.el.setAttribute('style', '')
    else if (lastAttribute === '') this.reset()

    if (this.inrtia) this.updatePosition(true)
  }

  flush () {
    this.toggleEvents(false)
  }
}

export default Carousel
