import { CustomBlending, Mesh, NearestFilter, Object3D, OneFactor, RGBAFormat, ShaderMaterial, WebGLRenderTarget } from 'three'
import { invokeMap } from 'lodash-es'

import resize from 'helpers/resize'
import snapshotFragment from 'canvas/shaders/snapshot.frag'
import snapshotVertex from 'canvas/shaders/snapshot.vert'
import { planeGeometry } from 'canvas/constants/geometries'
import { replaceChunks } from 'canvas/helpers/ShaderHelper'

class SceneSnapshot extends Object3D {
  constructor (scene) {
    super()
    this.scene = scene
    this.renderTarget = new WebGLRenderTarget(512, 512, {
      depthBuffer: false,
      depthWrite: false,
      stencilBuffer: false,
      minFilter: NearestFilter,
      magFilter: NearestFilter,
      format: RGBAFormat
    })

    this.material = new ShaderMaterial({
      transparent: true,
      blending: CustomBlending,
      blendSrc: OneFactor,
      fragmentShader: replaceChunks(snapshotFragment),
      vertexShader: replaceChunks(snapshotVertex),
      uniforms: {
        noise: { value: 0 },
        snap: { value: this.renderTarget.texture }
      }
    })
    this.plane = new Mesh(planeGeometry, this.material)
    this.plane.rotation.x = -Math.PI / 2
    this.add(this.plane)

    // this.renderOrder = -1

    this.camera = scene.camera.clone()
    this.max = 0
    this.resize(true)
    this.visible = false
  }

  beforeUpdate () {
    this.material.uniforms.noise.value = this.scene.noise
  }

  disable () {
    this.disabled = true
    this.visible = false
    this.scene.brushes.prepareSnapshot()
  }

  enable () {
    this.disabled = false
    this.save()
  }

  save () {
    if (this.disabled) return

    const { renderer, camera, scene, brushes, hovers } = this.scene

    this.visible = false
    brushes.prepareSnapshot()
    invokeMap(hovers, 'prepareSnapshot')

    renderer.setRenderTarget(this.renderTarget)
    renderer.render(scene, camera)
    renderer.setRenderTarget(null)

    brushes.clearAfterSnapshot()
    invokeMap(hovers, 'clearAfterSnapshot')
    this.visible = true
    this.scene.needsRender = true
  }

  resize (force) {
    const devicePixelRatio = this.scene.devicePixelRatio()
    const sizes = [resize.width() * devicePixelRatio, resize.height() * devicePixelRatio]
    this.renderTarget.setSize(...sizes)
    const { camera } = this.scene
    this.plane.scale.set(camera.right * 2, camera.top * 2, 1)
    this.save()
  }
}

export default SceneSnapshot
