
import { each, defer } from 'lodash-es'

import router from 'core/router'

const CROSS_TRANSITION = true
const LOAD_BEFORE_HIDE = true

class PageManager {
  state = { transitioning: false, loading: false, previous: false, next: false }
  page = { current: null, previous: null }

  constructor (container, pageSelector, routes, parameters) {
    this.parameters = parameters
    this.container = container
    this.pageSelector = pageSelector
    this.initializeRoutes(routes)
  }

  initializePage (pathName, PageClass) {
    this.title = document.querySelector('title')

    this.bodyClassName = document.body.className
    if (this.bodyClassName.indexOf('error404') > -1) pathName = '404'

    this.page.current = this.createPage(
      this.extractPage(this.container),
      PageClass
    )
    this.page.current.prepare(false)
    this.page.current.askShow(false)
    router.updatePageLinks()
  }

  initializeRoutes (routes) {
    const cb = PageClass => () => this.navigateTo(router.path(), PageClass)
    each(routes, obj => router.on(obj.route, cb(obj.pageClass)))
    defer(() => router.resolve())
  }

  navigateTo (pathName, PageClass) {
    if (!this.page.current) return this.initializePage(...arguments)

    if (this.state.transitioning || this.state.loading) {
      router.pause()
      router.lastRouteResolved().url = this.pathName
      router.navigate(this.pathName, true)
      router.resume()

      this.waitingForPage = pathName
      return
    }

    this.pathName = pathName
    this.state.next = PageClass.pageName || PageClass.prototype.constructor.name

    if (!LOAD_BEFORE_HIDE) {
      if (this.page.current) this.page.previous = this.page.current
      if (this.page.previous) this.hidePage()
    }

    this.load(pathName, PageClass)
  }

  load (pathName, PageClass) {
    if (this.xhr) {
      this.xhr.onload = this.xhr.onerror = null
      this.xhr.abort()
    }
    this.state.loading = true
    this.xhr = new XMLHttpRequest()
    this.xhr.withCredentials = true
    this.xhr.open('GET', pathName, true)
    this.xhr.responseType = 'document'
    this.xhr.onload = () => this.pageLoaded(pathName, PageClass)
    this.xhr.send()
  }

  pageLoaded (pathName, PageClass, event) {
    this.state.loading = false

    if (this.xhr.responseURL !== pathName && ~this.xhr.responseURL.indexOf(router.root)) {
      router.navigate(this.xhr.responseURL.replace(router.root, ''))
      return
    }

    // if ()

    // if (this.xhr.status === 302)

    if (this.xhr.status < 200 || this.xhr.status >= 400)
      return console.error('error loading page') //eslint-disable-line

    const page = this.xhr.response
    const el = this.extractPageFromXHR(page.body)

    if (!el) {
      window.history.back()
      return console.error('error loading page') //eslint-disable-line
    }

    this.bodyClassName = page.body.className
    this.title.textContent = page.title

    this.preparePage(el, PageClass)
    this.xhr.onload = null
    this.xhr = null
  }

  preparePage (el, PageClass) {
    if (LOAD_BEFORE_HIDE && this.page.current)
      this.page.previous = this.page.current

    this.state.transitioning = true
    this.page.current = this.createPage(el, PageClass)

    if (this.page.previous) {
      if (LOAD_BEFORE_HIDE) this.hidePage()
      else if (this.page.previous.state.hidden) this.pageHidden()
      if (CROSS_TRANSITION) this.showPage()
    } else {
      this.showPage()
    }
  }

  showPage () {
    const previousPage = this.state.previous
    this.page.current.prepare(previousPage)
    this.container.appendChild(this.page.current.el)
    document.body.className = this.bodyClassName

    defer(() => this.deferedShowPage(previousPage))
  }

  deferedShowPage (previousPage) {
    router.updatePageLinks()
    this.page.current.askShow(previousPage).then(this.pageShown)
  }

  transitionComplete () {
    if (!this.state.transitioning) return
    this.state.transitioning = false
  }

  pageShown = () => {
    if (CROSS_TRANSITION && this.page.previous) this.removePage()
    this.transitionComplete()

    if (this.waitingForPage) {
      router.navigate(this.waitingForPage, true)
      this.waitingForPage = null
    }
  }

  hidePage () {
    const nextPage = this.state.next
    this.state.previous = this.page.previous.pageName()
    this.page.previous.askHide(nextPage).then(this.pageHidden)
  }

  pageHidden = () => {
    if (!LOAD_BEFORE_HIDE && this.state.loading) return

    if (CROSS_TRANSITION) {
      if (!this.page.current || this.page.current.state.shown) {
        this.removePage()
        this.transitionComplete()
      }
    } else {
      this.removePage()
      if (LOAD_BEFORE_HIDE || this.page.current) this.showPage()
    }
  }

  removePage () {
    this.container.removeChild(this.page.previous.el)
    this.page.previous.flush()
    this.page.previous = null
    document.body.scrollTop = 0
  }

  extractPageFromXHR (el) { return this.extractPage(el) }
  extractPage (el) { return el.querySelector(this.pageSelector) }

  createPage = (el, PageClass) => new PageClass(el, this.parameters)

  resize = () => {
    if (this.page.current) this.page.current.resize()
    if (this.page.previous) this.page.previous.resize()
  }
}

export default PageManager
