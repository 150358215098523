import {

  // MeshStandardMaterial,
  // AxesHelper,
  Object3D
  // PerspectiveCamera
  // GridHelper,
  // PerspectiveCamera

} from 'three'
import Stats from 'stats.js'

import resize from 'helpers/resize'
import detect from 'helpers/detect'
// import { brushMaterial } from 'canvas/constants/materials'

// import OrbitControls from 'canvas/vendors/OrbitControls'
//
let stats, infos

function createStats () {
  stats = new Stats()
  document.body.appendChild(stats.dom)
}

function createInfo () {
  infos = document.createElement('div')
  infos.className = 'three-info'
  infos.style.position = 'fixed'
  infos.style.bottom = 0
  infos.style.left = 0
  infos.style.lineHeight = 1.5
  infos.style.fontFamily = 'Monaco'
  infos.style.padding = '10px'
  infos.style.background = '#111'
  infos.style.color = '#eee'
  infos.style.zIndex = 9999
  document.body.appendChild(infos)
}

function renderInfo (renderer) {
  if (!infos) createInfo()

  infos.innerHTML = `
    Programs: ${renderer.info.programs.length}<br/>
    Geometries: ${renderer.info.memory.geometries}<br/>
    Textures: ${renderer.info.memory.textures}<br/>
    Drawcalls: ${renderer.info.render.calls}<br/>
    Triangles: ${renderer.info.render.triangles}<br/>
  `
}

export default class OrbitHelper extends Object3D {
  constructor (scene) {
    super()

    if (!stats) createStats()
    if (!infos) createInfo()

    this.stats = stats

    // this.axesHelper = new AxesHelper(25)
    // this.add(this.axesHelper)

    // this.gridHelper = new GridHelper(10, 10, '#333333', '#999999')
    // this.add(this.gridHelper)

    this.camera = scene.camera
    this.scene = scene

    // this.camera = new PerspectiveCamera(50, 1, 1, 1e5)
    // this.camera.position.set(7.5, 7.5, 7.5)
    // this.camera.updateProjectionMatrix()

    // this.orbitControls = new OrbitControls(this.camera, scene.canvas)
    // this.orbitControls.update()

    addEventListener('keydown', this.onKeyDown.bind(this))

    this.interval = setInterval(() => {
      if (this.visible && !scene.locked) renderInfo(scene.renderer)
    }, 250)

    if (!detect.desktop) this.toggle()
  }

  toggle () {
    this.visible = !this.visible
    // this.orbitControls.enabled = this.visible
    stats.dom.style.display = this.visible ? '' : 'none'
    infos.style.display = this.visible ? '' : 'none'
  }

  onKeyDown (event) {
    if (event.code === 'KeyC') this.toggle()

    // if (event.code === 'KeyZ') {
    //   this.scene.brushes.lines.forEach(l => {
    //     l.material.wireframe = !l.material.wireframe
    //     l.material.uniforms.useMap = l.material.uniforms.useMap ? 0 : 1
    //   })
    // }
  }

  resize (scene) {
    this.camera.aspect = resize.ratio()
    this.camera.updateProjectionMatrix()
  }

  beforeUpdate (scene) {
    if (this.visible) this.stats.begin()
  }

  afterUpdate (scene) {
    if (this.visible) this.stats.end()
  }

  destroy () {
    clearInterval(this.interval)
  }
}
