import { Mesh, Object3D, TextureLoader, Vector2, Vector3 } from 'three'
import { defer, remove } from 'lodash-es'

import resize from 'helpers/resize'
import textures from 'canvas/constants/textures'
import scroll from 'core/scroll'
import { convertPath } from 'canvas/constants/curve'
import { planeGeometry } from 'canvas/constants/geometries'
import { basicMaterial } from 'canvas/constants/materials'

import BrushLine from './BrushLine'

export default class HoverLine extends Object3D {
  constructor (scene, target, color, shape, image = null) {
    super()
    this.target = target
    this.scene = scene
    this.shape = shape
    this.maxLength = 35
    this.frustumCulled = false
    this.createLine(color)

    // this.inrtia = new Inrtia({
    //   value: 0,
    //   precisionStop: 0.1,
    //   perfectStop: false,
    //   friction: 10
    // })

    if (image) this.createImage(image)
    scroll.instance().on(this.scroll)
  }

  update (point) {
    if (!this.scaleRatio) return

    const p = new Vector3(
      point.x * this.scaleRatio.x,
      0,
      point.y * this.scaleRatio.y
    )

    // console.log(p)
    if (this.line.points.length > this.maxLength) this.line.points.shift()
    this.line.addPoint(p, true)
    this.scene.needsRender = true
  }

  createImage (image) {
    this.image = new Mesh(planeGeometry, basicMaterial())
    this.image.material.map = new TextureLoader().load(image.getAttribute('data-gl'))
    this.image.renderOrder = 9998
    this.image.rotation.x = -Math.PI / 2
    this.add(this.image)
  }

  createLine (color) {
    this.line = new BrushLine(this.scene, textures.brushes[0], color)
    this.line.line.renderOrder = 9999
    this.line.curveControl = false
    this.line.cachePoints = false
    this.line.pointByUnit = 24

    const multiplier = .03
    this.line.material.uniforms.lineWidth.value *= multiplier
    this.line.material.uniforms.textureRatio.value *= multiplier
    this.line.material.uniforms.opacity.value = .9

    this.add(this.line)
  }

  beforeUpdate () {
    if (this.needScroll)
      this.updatePosition()

    this.line.beforeUpdate()
  }

  scroll = () => {
    // this.inrtia.to(scroll.scrollTop())
    this.needScroll = true
    this.scene.needsRender = true
  }

  lastPoint () {
    const { points } = this.line
    const last = points[points.length - 1]
    return {
      x: last.x / this.scaleRatio.x,
      y: last.y / this.scaleRatio.y
    }
  }

  animate (options) {
    const raw = this.shape[0]
    const size = this.bounds.height * this.scaleRatio.y * 1.05

    const offset = {
      x: (this.bounds.width / 2) * this.scaleRatio.x,
      y: (this.bounds.height / 2) * this.scaleRatio.y
    }

    const path = convertPath(raw, {
      size,
      pointByUnit: 8,
      simplifyPath: false,
      pointByUnitCurve: 24,
      offset
    })
    this.maxLength = Math.min(path.rawPoints.length, 70)

    if (!this.line.points.length)
      this.line.setPoints(this.line.computePath(path))

    return this.line.animatePath(options)
  }

  clearLine () {
    this.line.material.uniforms.progress.value = 0
  }

  resizeTarget (bounds, imageBounds) {
    this.bounds = bounds
    this.imageBounds = imageBounds

    defer(() => {
      const camera = this.scene.getCamera()
      const w = Math.abs(camera.left) + camera.right
      const h = Math.abs(camera.bottom) + camera.top

      this.scaleRatio = new Vector2(
        w / resize.width(),
        h / resize.vheight()
      )
      this.scaleOffset = new Vector2(
        -camera.right,
        -camera.top
      )

      if (this.image) {
        this.image.scale.set(
          this.imageBounds.width * this.scaleRatio.x,
          this.imageBounds.height * this.scaleRatio.y,
          1
        )
      }

      this.updatePosition()
    })
  }

  prepareSnapshot () {
    this.visible = false
  }

  clearAfterSnapshot () {
    this.visible = true
  }

  updatePosition () {
    const scrollTop = document.scrollingElement.scrollTop

    this.line.position.set(
      this.bounds.left * this.scaleRatio.x + this.scaleOffset.x,
      0,
      (this.bounds.top - scrollTop) * this.scaleRatio.y + this.scaleOffset.y
    )

    if (this.image) {
      this.image.position.set(
        (this.imageBounds.left + this.imageBounds.width / 2) * this.scaleRatio.x + this.scaleOffset.x,
        0,
        (this.imageBounds.top - scrollTop + this.imageBounds.height / 2) * this.scaleRatio.y + this.scaleOffset.y
      )
    }
  }

  removeLine (direct) {
    return (direct ? Promise.resolve() : this.line.reverseLine())
      .then(() => {
        this.scene.unregister(this)
        this.scene.scene.remove(this)
        remove(this.scene.items, (a) => a === this)
        this.destroy()
      })
  }

  destroy () {
    if (this.line) this.line.destroy()
    this.line = null
    scroll.instance().off(this.scroll)
  }
}
