import {
  Scene as _Scene,
  PerspectiveCamera,
  WebGLRenderer,
  AmbientLight
} from 'three'
import { defer, invokeMap, remove } from 'lodash-es'
import Emitter from 'tiny-emitter'
import raf from '@internet/raf'

import resize from 'helpers/resize'
import detect from 'helpers/detect'
import Helper from 'canvas/helpers/Helper'

class AScene extends Emitter {
  constructor (canvas, options) {
    super()
    this.items = []
    this.canvas = canvas
    this.options = options
    this.renderer = this.createRenderer()
    resize.add(this)
  }

  init () {
    if (this._initialized) return Promise.resolve()
    return this.loadAssets().then(() => {
      if (!this._initialized) this.initialized()
    })
  }

  loadAssets () {
    return new Promise(resolve => defer(resolve))
  }

  initialized () {
    this._initialized = true
    this.scene = this.createScene()
    this.camera = this.createCamera()
    if (module.hot && !detect.touch) this.createHelper()
    this.createLights()
    this.createItems()
    this.resize()
    this.start()
  }

  createHelper () {
    this.helper = new Helper(this)
    this.register(this.helper)
    this.scene.add(this.helper)
  }

  createCamera () {
    const camera = new PerspectiveCamera(75, 1, 1, 1000)
    camera.position.z = 5
    camera.position.y = 5
    camera.lookAt(0, 0, 0)
    return camera
  }

  createLights () {
    this.ambient = new AmbientLight(0x666666)
    this.scene.add(this.ambient)
  }

  unregister (element) {
    remove(this.items, (a) => a === element)
  }

  register (element) {
    this.items.push(element)
  }

  createScene () {
    return new _Scene()
  }

  createRenderer (options = {}) {
    const renderer = new WebGLRenderer({
      canvas: this.canvas,
      alpha: false,
      ...options
    })
    renderer.setPixelRatio(this.devicePixelRatio())

    return renderer
  }

  createItems () {}

  start = () => raf.add(this.tick)

  stop = () => raf.remove(this.tick)

  tick = () => this.render()

  beforeUpdate () {
    invokeMap(this.items, 'beforeUpdate', this)
  }

  afterUpdate () {
    invokeMap(this.items, 'afterUpdate', this)
  }

  getCamera () {
    return this.helper && this.helper.visible ? this.helper.camera : this.camera
  }

  triggerRender () {
    this.renderer.render(this.scene, this.getCamera())
  }

  render () {
    this.beforeUpdate()
    this.triggerRender()
    this.afterUpdate()
  }

  transitionDevicePixelRatio () {
    return Math.min(window.devicePixelRatio, detect.desktop ? 1.3 : 2)
  }

  devicePixelRatio () {
    return Math.max(1.5, Math.min(window.devicePixelRatio, detect.desktop ? 1.5 : 2))
  }

  resizeCamera () {
    if (!this.camera) return
    this.camera.aspect = resize.ratio()
    this.camera.updateProjectionMatrix()
    this.camera.updateMatrixWorld()
  }

  resize () {
    this.renderer.setSize(resize.width(), resize.vheight())
    this.resizeCamera()
    invokeMap(this.items, 'resize', this)
  }

  destroy () {
    this.stop()
    this.renderer.dispose()
    invokeMap(this.items, 'destroy', this)
    this.items = null
    resize.remove(this)
  }
}

export default AScene
