import raf from '@internet/raf'
import Inrtia from 'inrtia'
import anime from 'animejs'

import browser from 'helpers/browser'
// import detect from 'helpers/detect'
import background from 'modules/background/background'
import Reveal from 'modules/library/reveal/Reveal'
import color from 'helpers/color'
import detect from 'helpers/detect'
import paths from 'canvas/constants/paths'

export default class Epigraph extends Reveal {
  constructor (el) {
    super(el)
    this.el = el
    this.animated = true
    this.shapeId = +(this.el.getAttribute('data-path') || 0)
    this.useWebGL = !detect.touch && !(~el.className.indexOf('akira'))
    background.once('switch', this.initialize.bind(this))
  }

  initialize () {
    const title = this.el.querySelector('.epigraph__text, .akira-epigraph__text')

    if (this.useWebGL) {
      this.inrtia = new Inrtia({
        value: { x: 0, y: 0 },
        precisionStop: 1,
        perfectStop: false,
        friction: 15
      })
      const { color: _color } = window.getComputedStyle(title)
      this.gl = background.registerEpigraph(this.el, color.rgbToHex(_color), paths.shapes[this.shapeId], this.image)
    } else {
      this.svg = paths.svgs.shapes[this.shapeId].cloneNode(true)
      this.path = this.svg.querySelector('path')
      this.path.style.strokeDashoffset = anime.setDashoffset(this.path) + 'px'
      this.el.appendChild(this.svg)
    }
    this.resize()

    this.toggleEvents(true)
  }

  onAppear () {
    this.isAppeared = true
    this.animate()
  }

  onDisappeared () {
    this.isAppeared = false
    if (this.useWebGL) {
      this.gl && this.gl.clearLine()
    } else {
      this.path.style.strokeDashoffset = anime.setDashoffset(this.path) + 'px'
      anime.remove(this.path)
    }
    this.animated = true
  }

  show () {
    this.isShowed = true
    this.animate()
    super.show()
  }

  animate () {
    if (!this.isAppeared || !this.isShowed) return

    const options = { delay: 100, duration: 1600, easing: 'easeInOutSine' }

    if (this.useWebGL) {
      this.gl.animate(options).then(() => {
        this.animated = false
        this.inrtia.value = this.gl.lastPoint()
      })
    } else {
      anime({
        targets: this.path,
        strokeDashoffset: [anime.setDashoffset, 0],
        ...options
      })
    }
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    // this.el[method]('mouseover', this.mouseover)
    if (this.useWebGL) {
      this.el[method]('mousemove', this.mousemove)
      raf[add ? 'add' : 'remove'](this.update)
    }
  }

  mouseout = () => {
    this.hover = false
  }

  getPosition (event) {
    return {
      x: (event.pageX - this.bounds.left),
      y: (event.pageY - this.bounds.top)
    }
  }

  mouseover = (event) => {
    event = browser.mouseEvent(event)
    this.inrtia.value = this.getPosition(event)
  }

  mousemove = (event) => {
    if (this.animated) return
    event = browser.mouseEvent(event)
    this.inrtia.to(this.getPosition(event))
  }

  update = () => {
    if (!this.inrtia.stopped)
      this.gl && this.gl.update(this.inrtia.update())
  }

  flush () {
    super.flush()
    this.gl && this.gl.removeLine()
    this.toggleEvents(false)
  }

  resize () {
    super.resize()
    let imageBounds

    if (this.image) {
      const { left, height, width, top, bottom } = this.image.getBoundingClientRect()

      imageBounds = {
        bottom: bottom + this.scrollTop(),
        top: top + this.scrollTop(),
        left,
        height,
        width
      }
    }

    this.gl && this.gl.resizeTarget(this.bounds, imageBounds)
  }
}
