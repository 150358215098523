class Page {
  state = { shown: false, hidden: false }

  constructor (el) {
    this.el = el
  }

  pageName () { return this.constructor.pageName || this.constructor.name || true }

  prepare (previousPage) {
    this.el.style.opacity = 0
  }

  askShow = previousPage => {
    return Promise.resolve()
      .then(() => this.show(previousPage))
      .then(() => { this.state.shown = true })
  }

  show (previousPage) {
    this.el.style.opacity = 1
  }

  askHide = nextPage => {
    return Promise.resolve()
      .then(() => this.hide(nextPage))
      .then(() => { this.state.hidden = true })
  }

  hide (nextPage) {
    this.el.style.opacity = 0
  }

  flush () {}

  resize () {}
}

export default Page
