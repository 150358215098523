import style from 'core/style'
import SuperPage from 'navigation/SuperPage'
import Contact from 'sections/contact/Contact'

export default class Team extends SuperPage {
  colors= [style.light]

  constructor (el, options = {}) {
    super(...arguments)
  }

  invokeShow () {
    Contact.prototype.invokeShow.call(this)
  }
}
