
import anime from 'animejs'
import { reverse } from 'lodash-es'

import background from 'modules/background/background'

export default class Menu {
  constructor (el) {
    this.el = el
    this.opened = false
    this.animating = Promise.resolve()
    this.links = Array.from(el.querySelectorAll('.menu__link, .menu__langs'))
  }

  show () {
    this.opened = true
    this.animating.then(() => {
      this.animating = this._show()
    })
  }

  hide () {
    this.opened = false
    this.animating.then(() => {
      this.animating = this._hide()
    })
  }

  _show (pageName) {
    this.animating = true
    const p = background.openMenu()

    const timeline = anime.timeline({
      duration: 600,
      delay: (el, i, l) => i * 20 + 400,
      easing: 'easeOutQuad'
    })

    timeline.add(
      {
        targets: reverse(this.links),
        translateY: [-50, 0],
        opacity: [0, 1]
      },
      250
    )

    this.el.style.display = 'flex'
    return Promise.all([timeline.finished, p])
  }

  _hide () {
    // this.animating = true
    const p = background.closeMenu()
    // .then(() => {
    //   this.animating = false
    // })

    const timeline = anime.timeline({
      duration: 200,
      easing: 'easeInQuad',
      // delay: (el, i, l) => (l - i) * 50
      delay: (el, i, l) => i * 10
    })

    timeline.add(
      {
        translateY: [0, -50],
        opacity: [1, 0],
        targets: this.links
      },
      0
    )

    timeline.finished.then(() => {
      this.el.style.display = 'none'
    })

    return Promise.all([timeline.finished, p])
  }

  flush () {}

  resize () {}
}
