import './TextAnimator.scss'
import each from 'lodash/each'
import anime from 'animejs'

import text from 'helpers/text'

class TextAnimator {
  constructor (el, {
    offset = 130,
    duration = 800,
    vertical = true,
    scaleDelay = 1.75,
    easing = 'easeInOutQuad',
    letterByLetter = true,
    verticalPadding = 0.05,
    horizontalPadding = 0.05,
    automaticallyHidden = true
  } = {}) {
    this.el = el

    this.easing = easing
    this.offset = offset
    this.vertical = vertical
    this.duration = duration
    this.scaleDelay = scaleDelay
    this.letterByLetter = letterByLetter
    this.verticalPadding = verticalPadding
    this.horizontalPadding = horizontalPadding
    this.automaticallyHidden = automaticallyHidden

    this.prepareText()
  }

  getProperty = () => `translate${this.vertical ? 'Y' : 'X'}`
  getTransform = offset => `${this.getProperty()}(${offset}%)`

  prepareText = () => {
    if (this.letterByLetter)
      this.el.innerHTML = `<span class='text-animator'>${text.spanify(this.el.innerHTML, true, false)}</span>`
    else
      this.el.innerHTML = `<span class='text-animator'>${text.spanify(this.el.innerHTML, true, false)}</span>`

    this.words = this.el.querySelectorAll('.word')
    this.letters = this.el.querySelectorAll('.letter')

    // // Restore line-height
    // const style = window.getComputedStyle(this.el)
    // const lineHeight = parseFloat(style.lineHeight) / parseFloat(style.fontSize) + 'em'

    each(this.words, word => {
      word.style.padding = `${this.verticalPadding}em ${this.horizontalPadding}em`
      word.style.margin = `${-this.verticalPadding}em ${-this.horizontalPadding}em`
      // word.style.lineHeight = 0
    })

    each(this.letters, letter => {
      // letter.style.lineHeight = lineHeight
      if (this.automaticallyHidden) letter.style.transform = this.getTransform(this.offset)
    })
  }

  animateIn = (globalDelay = 0, invert = false) => {
    const delay = (this.duration * (this.scaleDelay - 1)) / this.letters.length
    const sign = invert ? -1 : 1

    return anime({
      targets: this.letters,
      [this.getProperty()]: [this.offset * sign, 0],
      easing: this.easing,
      duration: this.duration,
      delay: (el, i) => (i * delay) + globalDelay
    }).finished
  }

  animateOut = (globalDelay = 0, invert = false) => {
    const delay = (this.duration * (this.scaleDelay - 1)) / this.letters.length
    const sign = invert ? -1 : 1

    return anime({
      targets: this.letters,
      [this.getProperty()]: [0, this.offset * sign],
      easing: this.easing,
      duration: this.duration,
      delay: (el, i) => (i * delay) + globalDelay
    }).finished
  }

  flush () {
    this.el = null
    this.words = null
    this.letters = null
  }
}

export default TextAnimator
