
// import anime from 'animejs'

import Reveal from '../../library/reveal/Reveal'

// const gradient = 'linear-gradient(125deg, white 0%, white 33.33%, transparent 66.66%, transparent 100%)'
// const size = '300% 300%'

export default class WipeBlock extends Reveal {
  // progress = 0

  // _onAnimationStart () {
  //   this.el.style.willChange = '-webkit-mask-position, mask-position'
  //   this.el.style.backfaceVisibility = 'hidden'
  // }

  // _onAnimationStop () {
  //   this.el.style.willChange = ''
  //   this.el.style.backfaceVisibility = ''
  // }

  // _onScreen () {
  //   this.el.style.visibility = ''
  //   this.addMask()
  // }

  // _outOfScreen () {
  //   this.el.style.visibility = 'hidden'
  //   this.removeMask()
  // }

  // animateWipe (show = true) {
  //   if (show) this._onScreen()
  //   this._onAnimationStart()

  //   return anime({
  //     targets: this,
  //     progress: show ? 1 : 0,
  //     duration: 1000,
  //     easing: 'easeOutQuad',
  //     update: this._onUpdate
  //   }).finished.then(() => {
  //     this._onAnimationStop()
  //     if (!show) this._outOfScreen()
  //   })
  // }

  // onAppear () {
  //   this.animateWipe(true)
  // }

  // onDisappeared () {
  //   this.animateWipe(false)
  // }

  // _onUpdate = (p) => {
  //   const v = (1 - this.progress) * 100
  //   const progress = `${v}% ${v}%`
  //   this.el.style.webkitMaskPosition = progress
  //   this.el.style.maskPosition = progress
  // }

  // addMask () {
  //   this.el.style.webkitMaskImage = gradient
  //   this.el.style.maskImage = gradient
  //   this.el.style.webkitMaskSize = size
  //   this.el.style.maskSize = size
  // }

  // removeMask () {
  //   this.el.style.webkitMaskImage = ''
  //   this.el.style.maskImage = ''
  //   this.el.style.webkitMaskSize = ''
  //   this.el.style.maskSize = ''
  // }
}
