import anime from 'animejs'

import scroll from 'core/scroll'
// import resize from 'helpers/resize'
import detect from 'helpers/detect'

import Menu from '../menu/Menu'

export default class Header {
  constructor (el) {
    this.el = el
    this.prefix = this.el.className

    const menuEl = el.parentNode.querySelector('.menu')
    if (menuEl) this.menu = new Menu(menuEl)

    scroll.instance().on(this.onScroll)
    this.button = this.el.querySelector('.header__menu, .akira-header__menu')
    this.button && this.button.addEventListener('click', this.toggle)
    this.scroll = [...this.el.querySelectorAll('[data-scroll]')]
    this.scroll && this.scroll.forEach(s => s.addEventListener('click', this.scrollTo))

    // this.toggle()
  }

  show () {
    const mobile = window.getComputedStyle(this.button).display === 'block'
    this.el.style.visibility = 'visible'

    const query = mobile
      ? `.${this.prefix}__menu, .${this.prefix}__logo`
      : `.${this.prefix}__link, .${this.prefix}__title, .${this.prefix}__langs, .${this.prefix}__name`
    const links = Array.from(this.el.querySelectorAll(query))

    return anime({
      duration: 800,
      delay: (el, i, l) => i * 100,
      easing: 'easeOutQuad',
      targets: links,
      opacity: [0, 1]
    }).finished
  }

  barVisible = false;

  scrollTo = (event) => {
    const { currentTarget } = event
    event.preventDefault()
    const id = currentTarget.getAttribute('href')
    const target = document.body.querySelector(id)
    // scroll.scrollTo(target.offsetTop, 600)
    scroll.scrollTo(target.offsetTop - this.el.offsetHeight, 600)
  }

  toggle = () => {
    if (!this.enabled) return
    if (this.menu) {
      if (this.menu.opened) this.menu.hide()
      else this.menu.show()
    }
    document.body.classList.toggle('menu-opened', this.menu.opened)
  };

  onScroll = () => {
    const { scrollTop } = scroll
    const needBarVisible = scrollTop() > (detect.desktop ? 200 : 100)

    if (needBarVisible !== this.barVisible) {
      document.body.classList.toggle('bar-visible', needBarVisible)
      this.barVisible = needBarVisible
    }
  };

  flush () {
    this.scroll && this.scroll.forEach(s => s.removeEventListener('click', this.scrollTo))
    this.scroll = null

    this.button && this.button.removeEventListener('click', this.toggle)
    this.button = null

    this.menu && this.menu.flush()
    this.menu = null
    scroll.instance().off(this.onScroll)
  }

  resize () {}
}
