import router from 'core/router'
import style from 'core/style'
import SuperPage from 'navigation/SuperPage'

export default class Login extends SuperPage {
  colors= [style.brown]

  constructor (el, options = {}) {
    super(el, {})

    this.form = el.querySelector('.login__form')
    this.input = el.querySelector('.login__input')
    this.error = el.querySelector('.login__error')

    this.toggleEvents(true)
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.form[method]('submit', this.onFormSubmit)
  }

  onFormSubmit = (event) => {
    if (this.loading) return
    this.loading = true
    event.preventDefault()
    const xhr = new XMLHttpRequest()
    xhr.open('POST', 'api/login', true)
    xhr.responseType = 'json'
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    xhr.onload = this.onResponse
    xhr.send(`password=${this.input.value}`)
  }

  onResponse = (event) => {
    this.loading = false
    const { response } = event.target

    if (response && response.success)
      router.navigate(this.form.getAttribute('data-redirect'))
    else
      this.error.classList.add('visible')
  }

  flush () {
    this.toggleEvents(false)
    super.flush()
  }
}
