
import Bowser from 'bowser'

const ua = window.navigator.userAgent
const _bowser = Bowser.getParser(ua)

const platform = _bowser.getPlatformType()
const browser = _bowser.getBrowserName()
const os = _bowser.getOSName()
const model = (_bowser.getPlatform().model || '').toLowerCase()

const test = {
  desktop: platform === 'desktop',
  mobile: platform === 'mobile',
  tablet: platform === 'tablet' || platform === 'tv',

  opera: browser === 'Opera' || browser === 'Opera Coast',
  safari: browser === 'Safari',
  edge: browser === 'Microsoft Edge',
  ie: browser === 'Internet Explorer',
  chrome: browser === 'Chrome' || browser === 'Chromium',
  firefox: browser === 'Firefox',
  uc_browser: browser === 'UC Browser',

  android: os === 'Android',
  ios: os === 'iOS',
  windows: os === 'Windows',
  linux: os === 'Linux',
  macos: os === 'macOS',

  iphone: model === 'iphone',
  ipad: model === 'ipad',

  touch: !!window.matchMedia('screen and (hover: none)').matches,

  result: _bowser.parsedResult
}

const addClass = (valid, key) => document.documentElement.classList.add((valid ? '' : 'not-') + key)

addClass(test.mobile, 'mobile')
addClass(test.tablet, 'tablet')
addClass(test.desktop, 'desktop')
addClass(test.windows, 'windows')
addClass(test.ios, 'ios')

export default test
