
import anime from 'animejs'

import resize from 'helpers/resize'

import Reveal from '../../library/reveal/Reveal'

const ease = anime.penner.easeInQuad()

export default class ParralaxBlock {
  constructor (el) {
    this.el = el
    this.offset = (+el.getAttribute('data-parralax')) || 50
    this.clamp = el.getAttribute('data-clamp') ? el.getAttribute('data-clamp').split(',').map(v => +v) : [-1.5, 1.5]
    this.init()
  }

  init () {
    Reveal.prototype.init.call(this)
  }

  shown () {
    Reveal.prototype.shown.call(this)
  }

  scrollTop () {
    return Reveal.prototype.scrollTop.call(this)
  }

  scroll = () => {
    const limit = resize.height() / 2
    const center = (this.bounds.top + this.bounds.height / 2) - this.scrollTop()
    let progress = (limit - center) / limit
    progress = Math.min(this.clamp[1], Math.max(this.clamp[0], progress))

    const bottomVisible = this.bounds.bottom - this.scrollTop()
    const visible = progress > 0 && bottomVisible > 0

    if (visible !== this.visible) {
      if (visible) this.onScreen()
      else this.outOfScreen()
      this.visible = visible
    }
    if (progress !== this.progress) {
      this.onUpdate(progress)
      this.progress = progress
    }
  }

  onUpdate (p) {
    this.el.style.transform = `translateY(${p * this.offset}px)`
  }

  onScreen () {

  }

  outOfScreen () {
  }

  ease (v) {
    return ease(v)
  }

  resize () {
    Reveal.prototype.resize.call(this)
  }

  flush () {
    Reveal.prototype.flush.call(this)
  }
}
