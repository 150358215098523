import { map, each } from 'lodash-es'

import resize from 'helpers/resize'
import style from 'core/style'
import ActivitiesNav from 'modules/activities-nav/ActivitiesNav'
import SuperPage from 'navigation/SuperPage'
import router from 'core/router'

export default class Activities extends SuperPage {
  colors= [style.brown]

  constructor (el, options = {}) {
    super(el, {
      moduleMap: {
        '.activities__nav': ActivitiesNav
      }
    })

    this.button = el.querySelector('.activities__more-button')

    if (this.button) {
      this.currentPage = 0
      this.totalPage = (+this.button.getAttribute('data-total-page'))
      this.pageContainer = el.querySelector('.activities__more-list')
    }
    this.toggleEvents(true)
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.button && this.button[method]('click', this.loadMore)
  }

  loadMore = (event) => {
    this.currentPage++
    if (this.loading || this.currentPage >= this.totalPage) return

    this.loading = true

    const xhr = new XMLHttpRequest()
    xhr.open('GET', `api/activites/${this.currentPage}`, true)
    xhr.responseType = 'document'
    xhr.onload = this.pageLoaded
    xhr.send()
  }

  pageLoaded = (event) => {
    const xhr = event.currentTarget
    const content = xhr.response
    this.loading = false

    const items = Array.from(content.querySelectorAll('.article-item'))
    each(this.moduleMap, (Module, selector) => {
      this.modules.push(...map(
        content.querySelectorAll(selector),
        el => new Module(el, { shown: true })
      ))
    })

    items.forEach(item => {
      this.pageContainer.appendChild(item)
    })

    resize.force()
    router.updatePageLinks()

    if (this.currentPage >= this.totalPage - 1)
      this.button.style.display = 'none'
  }

  flush () {
    this.toggleEvents(false)
    super.flush()
  }
}
