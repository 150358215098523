import Carousel from 'modules/library/carousel/Carousel'

export default class ActivityCarousel {
  constructor (el) {
    this.el = el
    this.getElements()
    this.instanceComponents()
    this.toggleEvents(true)
  }

  getElements () {
    this.inner = this.el.querySelector('.activity__carousel-list')
    this.next = this.el.querySelector('.activity__carousel-arrow.next')
    this.prev = this.el.querySelector('.activity__carousel-arrow.prev')
  }

  instanceComponents () {
    this.carousel = new Carousel(this.inner)
    this.carousel.prevent = true
    this.onCarouselUpdate(0)
  }

  toggleEvents (add = true) {
    const emitMethod = add ? 'on' : 'off'
    const method = add ? 'addEventListener' : 'removeEventListener'

    this.carousel[emitMethod]('update', this.onCarouselUpdate)
    this.prev[method]('click', this.onPrev)
    this.next[method]('click', this.onNext)
  }

  onPrev = () => {
    this.carousel.prev()
  }

  onNext = () => {
    this.carousel.next()
  }

  onCarouselUpdate = (step) => {
    this.prev.classList.toggle('disable', step === 0)
    this.next.classList.toggle('disable', step >= this.carousel.total - 1)
    // this.manifestoCounter.innerText = `${step + 1} / 2`
    // this.manifesto.style.setProperty('--progress', (step + 1) / 2)
  }

  resize () {
    this.carousel.resize()
  }

  flush () {
    this.toggleEvents(false)

    this.carousel.flush()
    this.carousel = null
  }
}
