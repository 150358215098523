// import svgMesh3d from 'svg-mesh-3d'

import { set } from 'lodash-es'

import { convertPath } from './curve'

const svgs = {}
const paths = { svgs }

const load = (src, key, convert = true) => new Promise(resolve => {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', src)
  xhr.responseType = 'document'
  xhr.onload = () => {
    const _paths = Array.from(xhr.response.querySelectorAll('path'))
    set(paths, key, _paths.map(path => convert ? convertPath(path) : path))
    set(svgs, key, xhr.response.querySelector('svg'))
    resolve()
  }

  xhr.send()
})

paths.load = () => {
  if (paths.lines) return Promise.resolve()
  paths.lines = []

  return Promise.all([
    load('../assets/canvas/scroll/scroll.svg', 'scroll'),
    Promise.all([
      '../assets/canvas/paths/path1.svg',
      '../assets/canvas/paths/path2.svg'
    ].map((src, i) => load(src, `lines[${i}]`))),
    Promise.all([
      '../assets/canvas/shapes/shape1.svg',
      '../assets/canvas/shapes/shape2.svg',
      '../assets/canvas/shapes/shape3.svg'
    ].map((src, i) => load(src, `shapes[${i}]`, false)))
  ]).then(() => {
  })
}

export default paths
