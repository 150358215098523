import { findLastIndex } from 'lodash-es'

import scroll from 'core/scroll'

export default class ActivitiesNav {
  constructor (el) {
    this.el = el
    this.getElements()
    this.toggleEvents(true)
  }

  getElements () {
    this.links = Array.from(this.el.querySelectorAll('.activities__nav-link'))

    this.blocks = this.links.map((l, i) => {
      l.setAttribute('data-id', i)
      return this.el.parentNode.querySelector(l.getAttribute('href'))
    })
  }

  toggleEvents (add = true) {
    const emitMethod = add ? 'on' : 'off'
    const method = add ? 'addEventListener' : 'removeEventListener'
    scroll.instance()[emitMethod](this.scroll)
    this.links.forEach(l => l[method]('click', this.click))
  }

  click=() => {
    event.preventDefault()
    const id = +event.currentTarget.getAttribute('data-id')
    this.animating = true
    scroll.scrollTo(this.steps[id]).then(() => {
      this.animating = false
      this.scroll()
    })
  }

  scroll = () => {
    if (this.animating) return
    const current = findLastIndex(this.steps, (step, i) => step <= scroll.scrollTop())
    if (current === this.current) return
    this.links.forEach((l, i) => l.classList.toggle('current', current === i))
    this.current = current
  }

  resize () {
    this.top = parseInt(window.getComputedStyle(this.el).top)
    this.steps = this.blocks.map((block) => {
      return Math.ceil(block.getBoundingClientRect().top + scroll.scrollTop() - this.top)
    })
  }

  flush () {
    this.toggleEvents(false)
  }
}
