import { filter, invokeMap } from 'lodash-es'

import style from 'core/style'
import SuperPage from 'navigation/SuperPage'

export default class Contact extends SuperPage {
  colors= [style.light]

  constructor (el, options = {}) {
    super(...arguments)
  }

  invokeShow () {
    const first = filter(this.modules, m => !!m.title)
    const others = filter(this.modules, m => !m.title)

    invokeMap(first, 'show')
    setTimeout(() => invokeMap(others, 'show'), 900)
  }
}
