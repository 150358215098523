import style from 'core/style'
import ImageRoll from 'modules/image-roll/ImageRoll'
import SuperPage from 'navigation/SuperPage'

export default class Home extends SuperPage {
  colors= [style.light]

  constructor (el, options = {}) {
    super(el, Object.assign({}, options, {
      moduleMap: {
        '.image-roll': ImageRoll
      }
    }))
  }
}
