import { ShaderChunk } from 'three'

function replaceThreeChunkFn (a, b) {
  return ShaderChunk[b] + '\n'
}

function replaceChunks (glsl) {
  return glsl.replace(/\/\/\s?chunk\(\s?(\w+)\s?\);?/g, replaceThreeChunkFn)
}

export { replaceChunks }
