import PageManager from 'navigation/SuperPageManager'
import scroll from 'core/scroll'
import resize from 'helpers/resize'
import background from 'modules/background/background'
import Intro from 'sections/intro/Intro'
import router from 'core/router'

import Home from './home/Home'
import Activities from './activities/Activities'
import Activity from './activity/Activity'
import Akira from './akira/Akira'
import Team from './team/Team'
import Contact from './contact/Contact'
import Login from './login/Login'

class Main {
  constructor (el) {
    this.el = el
    this.createVirtualScroll()

    resize.setRuler(document.querySelector('.background__ruler'))
    this.listenResize()

    scroll.init()

    background.init().then(() => {
      this.createPageManager()
    })
  }

  createPageManager () {
    this.pageManager = new PageManager(
      document.querySelector('.container'),
      '.page',
      [
        { route: '/home', pageClass: Home },
        { route: '/activities', pageClass: Activities },
        { route: '/activities/*', pageClass: Activity },
        { route: '/team', pageClass: Team },
        { route: '/contact', pageClass: Contact },
        { route: '/akira*', pageClass: Akira },
        { route: '/login', pageClass: Login },
        { route: '/', pageClass: Intro }
      ], {
        mapModule: {
          // '.reveal-block': Reveal,
          // '.reveal-text': TextReveal,
          // '.img-scroller': ImageScroller
        }
      })
    this.resize()
  }

  createVirtualScroll () {
    scroll.init()
  }

  listenResize () {
    resize.add(this)
    this.resize()
  }

  resize = () => {
    this.pageManager && this.pageManager.resize()
  };
}

export default Main
