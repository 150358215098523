import { NearestFilter, RepeatWrapping, RGBFormat, TextureLoader, Vector4 } from 'three'
import { BasisTextureLoader } from 'three/examples/jsm/loaders/BasisTextureLoader'

import detect from 'helpers/detect'

const useJpg = !detect.desktop

const BRUSHES = [
  {
    src: '../assets/canvas/brushes/brush2.' + (useJpg ? 'jpg' : 'basis'),
    items: [
      {
        channel: 'r',
        clamp: new Vector4(0, .3, .7, 1),
        lineWidth: 1.2
      },
      {
        channel: 'g',
        clamp: new Vector4(0., .15, .85, 1),
        lineWidth: 1
      },
      {
        channel: 'b',
        clamp: new Vector4(0., .15, .85, 1),
        lineWidth: 1
      }
    ]
  }
]

const textures = {}

textures.load = (renderer) => {
  if (textures.brushes) return Promise.resolve()
  textures.brushes = []

  const basisLoader = new BasisTextureLoader()
  basisLoader.setTranscoderPath('/assets/wasm/')
  basisLoader.detectSupport(renderer)

  const loader = new TextureLoader()

  return Promise.all(BRUSHES.map(({ src, ...data }, i) => new Promise(resolve => {
    const isBasis = ~src.indexOf('.basis')
    const _loader = isBasis ? basisLoader : loader
    _loader
      .load(src, (texture) => {
        if (!isBasis) texture.format = RGBFormat
        texture.wrapS = texture.wrapT = RepeatWrapping
        texture.minFilter = texture.magFilter = NearestFilter

        data.items.forEach((info) => {
          textures.brushes.push({ texture, ...info })
        })
        resolve()
      })
  })))
}

export default textures
