const _if = condition => new Promise((resolve, reject) => (condition ? resolve : reject)())
const wait = time => new Promise(resolve => setTimeout(resolve, time))

const defer = () => {
  const obj = {}
  obj.promise = new Promise((resolve, reject) => {
    obj.resolve = resolve
    obj.reject = reject
  })
  return obj
}

export default {
  if: _if,
  wait,
  defer
}
