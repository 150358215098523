import { OrthographicCamera, Vector2 } from 'three'
// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

import resize from 'helpers/resize'
import { setMaterialResolution } from 'canvas/constants/materials'
// import noiseFragment from 'canvas/shaders/noise.frag'
// import noiseVertex from 'canvas/shaders/noise.vert'

import AScene from './abstract/AScene'
import paths from './constants/paths'
import textures from './constants/textures'
import BrushLines from './objects/BrushLines'
import SceneSnapshot from './objects/SceneSnapshot'
import HoverLine from './objects/HoverLine'

class Scene extends AScene {
  loadAssets () {
    this.resolution = new Vector2()
    this.noise = 0
    setMaterialResolution(this.resolution)

    return Promise.all([
      paths.load(),
      textures.load(this.renderer)
    ]).then(() => {
      this.emit('ready')
    })
  }

  initialized () {
    super.initialized()
    // this.createPostProcessing()
  }

  // createPostProcessing () {
  //   this.composer = new EffectComposer(this.renderer)
  //   this.renderPass = new RenderPass(this.scene, this.camera)
  //   this.composer.addPass(this.renderPass)

  //   this.shaderPass = new ShaderPass({
  //     uniforms: {
  //       tDiffuse: { value: null },
  //       amount: { value: 0 }
  //     },
  //     vertexShader: noiseVertex,
  //     fragmentShader: noiseFragment
  //   })

  //   // this.renderPass.renderToScreen = true
  //   this.shaderPass.renderToScreen = true
  //   this.composer.addPass(this.shaderPass)
  // }

  createCamera () {
    const camera = new OrthographicCamera(-1, 1, -1, 1, 0, 10)
    camera.position.set(0, 5, 0)
    camera.lookAt(0, 0, 0)

    return camera
  }

  createLights () {}

  triggerRender () {
    return super.triggerRender()
    // this.shaderPass.uniforms.amount.value += 0.01
    // this.composer.render()
  }

  createRenderer () {
    const renderer = super.createRenderer({
      transparent: true,
      antialias: true,
      alpha: true,
      depth: false,
      // premultipliedAlpha: true,
      // logarithmicDepthBuffer: false,
      powerPreference: 'high-performance',
      stencil: false
    })

    // renderer.sortObjects = false
    renderer.setClearColor(0xffffff, 0)
    return renderer
  }

  createItems () {
    this.hovers = []

    this.brushes = new BrushLines(this)
    this.register(this.brushes)
    this.scene.add(this.brushes)

    this.snapshot = new SceneSnapshot(this)
    this.register(this.snapshot)
    this.scene.add(this.snapshot)
  }

  resizeCamera () {
    if (!this.camera) return

    const aspect = resize.width() / resize.vheight()
    const viewSize = 5
    this.camera.viewSize = viewSize

    if (aspect > 1) {
      this.camera.left = -viewSize
      this.camera.right = viewSize
      this.camera.top = viewSize / aspect
      this.camera.bottom = -viewSize / aspect
    } else {
      this.camera.left = -aspect * viewSize
      this.camera.right = aspect * viewSize
      this.camera.top = viewSize
      this.camera.bottom = -viewSize
    }

    this.camera.updateProjectionMatrix()
    this.camera.updateMatrixWorld()
  }

  cache () {
    if (!this.snapshot) return
    this.snapshot.save()
    this.needsRender = true
  }

  lock () {
    this.locked = true
  }

  unlock () {
    this.locked = false
  }

  addHoverLine (el, color, shape, image) {
    const line = new HoverLine(this, el, color, shape, image)
    this.register(line)
    this.scene.add(line)
    this.hovers.push(line)
    return line
  }

  animateBackground (color) {
    this.color = color
    return this.brushes.triggerAnimation(...arguments)
  }

  clearBackground (direct = false, options = {}) {
    if (!this._initialized) return Promise.resolve()
    if (this.snapshot) this.snapshot.disable()

    return Promise.all([
      this.brushes.removeLines(direct, options),
      Promise.all(this.hovers.map((l) => l.removeLine(direct, options)))
    ]).then(() => {
      if (this.snapshot) this.snapshot.enable()
    })
  }

  addScrollLine () {
    this.brushes.addScrollLine()
  }

  beforeUpdate () {
    this.noise = (this.noise + 0.01) % 10
    super.beforeUpdate()
  }

  render () {
    if (this.locked) return
    return super.render()

    // if (this.needsRender) {
    //   this.needsRender = false
    //   return super.render()
    // } else { this.brushes.beforeUpdate() }
  }

  getCamera () {
    return this.camera
  }

  resize () {
    if (this.resolution)
      this.resolution.set(resize.width(), resize.vheight())
    super.resize()
  }

  destroy () {
    super.destroy()
  }
}

export default Scene
