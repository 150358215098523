import VirtualScroll from 'virtual-scroll'
import Emitter from 'tiny-emitter'
import anime from 'animejs'

import detect from 'helpers/detect'
import 'scrolling-element'

let instance
let scrollTop = document.scrollingElement.scrollTop
// const scrollBeforeLock = false
let scrollLocked = false
let animating = false

const desktop = !detect.mobile && !detect.tablet
const virtual = desktop && !detect.windows
const emitter = new Emitter()
const multiplier = detect.firefox ? 0.4 : detect.windows ? 1 : 0.1

document.scrollingElement.classList.add('scrolling-element')

const init = () => {
  instance = new VirtualScroll({
    el: document.scrollingElement,
    mouseMultiplier: multiplier,
    firefoxMultiplier: 30 / multiplier,
    passive: false
  })

  instance.on(onScroll)

  // if (virtual) {
  //   window.addEventListener('touchstart', touchStart, { passive: false })
  //   window.addEventListener('touchmove', touchMove, { passive: false })
  // }
  // window.addEventListener('touchmove', pageScroll, { passive: true })
  window.addEventListener('scroll', pageScroll, { passive: true })
}

const lock = () => {
  scrollLocked = true
  // scrollBeforeLock = scrollTop
  // document.scrollingElement.classList.add('scroll-lock')
}

const unlock = () => {
  scrollLocked = false
  // document.scrollingElement.classList.remove('scroll-lock')
  // if (scrollBeforeLock) document.scrollingElement.scrollTop = scrollBeforeLock
  // scrollBeforeLock = false
}
let _touchStart
const touchStart = (event) => {
  const t = (event.targetTouches) ? event.targetTouches[0] : event
  _touchStart = t.clientY
}

const touchMove = (event) => {
  event.preventDefault()
}

const pageScroll = (event) => {
  const _scrollTop = document.scrollingElement.scrollTop
  if (_scrollTop === scrollTop) return

  testScroll(_scrollTop)
  scrollTop = _scrollTop
  emitter.emit('scroll', event)
}

const getDelta = (event) => {
  const { deltaY, originalEvent } = event

  if (originalEvent.type === 'touchmove') {
    const t = (event.originalEvent.targetTouches) ? event.originalEvent.targetTouches[0] : event
    const dX = (t.clientY - _touchStart) * instance.options.touchMultiplier
    _touchStart = t.clientY
    return dX
  }

  return deltaY
}

const onScroll = (event) => {
  if (event && scrollLocked) {
    event.originalEvent.preventDefault()
    return
  }

  if (virtual && event) {
    event.originalEvent.preventDefault()
    if (animating) return
    const deltaY = getDelta(event)
    document.scrollingElement.scrollTop -= deltaY
  }

  if (virtual) {
    const _scrollTop = document.scrollingElement.scrollTop
    testScroll(_scrollTop)
    scrollTop = _scrollTop
  }

  if (desktop && event) emitter.emit('scroll', event)
  emitter.emit('virtual', event)
}

let startScrollDown = false
let hasScrollDown = false
let lastScrollTop = Infinity
const scrollClassname = 'scroll-down'
const topOffset = 100
const offset = 50

const testScroll = (newScrollTop) => {
  if (newScrollTop === scrollTop) return
  if (newScrollTop > Math.max(0, scrollTop)) scrollDown(newScrollTop)
  else scrollUp(newScrollTop)
}

const scrollDown = (newScrollTop) => {
  if (!startScrollDown) {
    startScrollDown = true
    lastScrollTop = newScrollTop
  }

  if (hasScrollDown || Math.abs(lastScrollTop - newScrollTop) < offset) return
  document.body.classList.add(scrollClassname)
  lastScrollTop = newScrollTop
  hasScrollDown = true
}

const scrollUp = (newScrollTop) => {
  if (startScrollDown) {
    startScrollDown = false
    lastScrollTop = newScrollTop
  }

  if (!hasScrollDown || (Math.abs(lastScrollTop - newScrollTop) < offset && newScrollTop > topOffset)) return
  document.body.classList.remove(scrollClassname)
  hasScrollDown = false
}

const scrollTo = (_scrollTop, duration = 400, easing = 'easeInOutQuad') => {
  if (animating) return Promise.resolve()
  animating = true
  return anime({
    targets: document.scrollingElement,
    scrollTop: [scrollTop, _scrollTop],
    // scrollTop: _scrollTop,
    duration,
    easing,
    complete: () => (animating = false),
    update: () => pageScroll({})
  }).finished
}

const scroll = {
  init,
  lock,
  unlock,
  scrollTo,
  instance: () => ({
    on: (cb) => emitter.on('scroll', cb),
    off: (cb) => emitter.off('scroll', cb)
  }),
  virtual: () => ({
    on: (cb) => emitter.on('virtual', cb),
    off: (cb) => emitter.off('virtual', cb)
  }),
  scrollTop: () => scrollTop,
  reset: () => onScroll() && unlock()
}

export default scroll
