import style from 'core/style'
import SuperPage from 'navigation/SuperPage'

export default class Akira extends SuperPage {
  colors= [style.brown]

  constructor (el, options = {}) {
    super(el, options)
  }
}
