import debounce from 'lodash/debounce'

import style from 'core/style'

import detect from './detect'

let cache = {}
let nobarvh

let root = false, ruler = false
let ignoreScrollResize = false

const dimension = () => {
  if (cache.dimension) return cache.dimension
  if (nobarvh === undefined) nobarvh = ruler && ruler.offsetHeight === window.innerHeight

  const dimension = {
    width: !root ? window.innerWidth : root.offsetWidth,
    height: window.innerHeight,
    vheight: detect.ios && ruler
      ? nobarvh ? screen.height : ruler.offsetHeight
      : window.innerHeight
    // height: !root ? window.innerHeight : root.offsetHeight
  }

  // Ignore ios resize on scroll (wheen bottom bar disappear)
  if (ignoreScrollResize &&
    detect.iphone &&
    cache.dimension &&
    cache.dimension.width === dimension.width)
    dimension.height = cache.dimension.height

  // alert(cache.nobarvh + ' ' + dimension.vheight + ' ' + window.outerHeight + ' ' + ruler.offsetHeight + ' ' + window.innerHeight)
  // if (!cache.dimension || cache.dimension.width !== dimension.width)
  //   alert(ruler.offsetHeight + ' ' + window.innerHeight + '' + window.outerHeight)
  // detect.ios && ruler
  // ? ruler.offsetHeight === window.innerHeight ? document.documentElement.clientHeight : ruler.offsetHeight
  // : window.innerHeight

  dimension.ratio = dimension.width / dimension.height

  return (cache.dimension = dimension)
}

const width = () => dimension().width
const ratio = () => dimension().ratio
const height = () => dimension().height
const vheight = () => dimension().vheight

const testMQ = (mq) => () => cache[mq] || (cache[mq] = window.matchMedia(mq).matches)

const clear = () => {
  cache = {}
}

let listeners = []

const resize = debounce(() => {
  clear()
  listeners.forEach((listener) => listener.resize && listener.resize())
}, 100)

window.addEventListener('resize', resize)

const add = (listener) => listeners.push(listener)
const remove = (listener) => (listeners = listeners.filter(item => item !== listener))

const react = (Target) => {
  const componentDidMount = Target.prototype.componentDidMount
  Target.prototype.componentDidMount = function () {
    add(this)
    componentDidMount && componentDidMount.call(this)
  }

  const componentWillUnmount = Target.prototype.componentWillUnmount
  Target.prototype.componentWillUnmount = function () {
    remove(this)
    componentWillUnmount && componentWillUnmount.call(this)
  }
}

const setRoot = r => (root = r)
const setRuler = r => (ruler = r)
const setIgnoreScrollResize = i => (ignoreScrollResize = !!i)

export default {
  dimension,
  width,
  height,
  vheight,
  ratio,
  clear,
  react,
  add,
  remove,
  setRoot,
  setRuler,
  force: resize,
  setIgnoreScrollResize,
  mq: {
    tablet: testMQ(`(max-width: ${style.tabletMaxWidth})`),
    tabletPortrait: testMQ(`(max-width: ${style.tabletPortraitMaxWidth})`),
    phone: testMQ(`(max-width: ${style.phoneMaxWidth})`),
    phonePortrait: testMQ(`(max-width: ${style.phonePortraitMaxWidth})`)
  }
}
