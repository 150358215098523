import style from 'core/style'
import ActivityCarousel from 'modules/activity-carousel/ActivityCarousel'
import SuperPage from 'navigation/SuperPage'

export default class Activity extends SuperPage {
  colors= [style.brown]

  constructor (el, options = {}) {
    super(el, {
      moduleMap: {
        '.activity__carousel': ActivityCarousel
      }
    })

    this.imagesToLoad = [el.querySelector('.epigraph__image img')]
  }
}
