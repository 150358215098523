import Emitter from 'tiny-emitter'

import Scene from 'canvas/Scene'
import style from 'core/style'
import promise from 'helpers/promise'

const emitter = new Emitter()
let scenes, current, other, loadingPromise

const switchCanvas = () => {
  const tmp = current
  current = other
  other = tmp
}

const startSwitch = ({ zIndex = 200 } = {}) => {
  switchCanvas()

  other.lock()
  current.unlock()
  current.clearBackground(true)
  current.canvas.style.display = 'block'
  current.canvas.style.zIndex = zIndex

  return current.init()
    .then(() => promise.wait(1))
    .then(() => {
      emitter.emit('switch')
    })
}

const endSwitch = () => {
  other.lock()
  other.canvas.style.display = 'none'
  current.canvas.style.zIndex = -1
  current.unlock()
}

const init = () => {
  const canvases = Array.from(document.querySelectorAll('.background__canvas'))
  scenes = canvases.map(canvas => new Scene(canvas))
  current = scenes[0]
  other = scenes[1]

  loadingPromise = current.init()
    .then(() => emitter.emit('ready'))

  scenes.forEach(scene => {
    scene.on('update', canvasUpdate)
    scene.on('paintReady', paintComplete)
  })

  endSwitch()

  return loadingPromise
}

const overlapAnimation = (colors) => {
  return loadingPromise
    .then(() => startSwitch())
    .then(() => {
      current.animateBackground(style.red, 1)
      return promise.wait(300)
    })
    .then(() => current.animateBackground(colors[0], 0))
    .then(() => endSwitch())
}

const openManifesto = () => {
  return loadingPromise
    .then(() => startSwitch({ zIndex: 29 }))
    .then(() => {
      current.animateBackground(style.light, 0)
    })
}

const closeManifesto = () => {
  return current.clearBackground(false).then(() => {
    switchCanvas()
    endSwitch()
  })
}

const openMenu = () => {
  return loadingPromise
    .then(() => startSwitch({ zIndex: 29 }))
    .then(() => {
      current.animateBackground(style.red, 1)
      return promise.wait(300)
    })
    .then(() => current.animateBackground(style.brown, 0))
}

const closeMenu = closeManifesto

const registerEpigraph = (el, color, shape, image) => {
  return current.addHoverLine(el, color, shape, image)
}

const removeLines = () => {
  return current.clearBackground().then(() => {
    endSwitch()
    current.color = style.red
  })
}

const canvasUpdate = (e) => {
  emitter.emit('update', e)
}

const paintComplete = (e) => {
  emitter.emit('paintReady')
}

const addScrollLine = () => {
  current.addScrollLine()
}

const background = Object.assign(emitter, {
  init,
  overlapAnimation,
  removeLines,
  openManifesto,
  closeManifesto,
  registerEpigraph,
  addScrollLine,
  openMenu,
  closeMenu,
  current: () => current
})

export default background
