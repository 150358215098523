import { Mesh, Object3D, Vector3, BufferGeometry } from 'three'
// import { MeshLine } from 'three.meshline'
import anime from 'animejs'
import { last } from 'lodash-es'

import detect from 'helpers/detect'
import math from 'helpers/math'
import { brushMaterial } from 'canvas/constants/materials'
import { updateGeometry, convertToCurve } from 'canvas/constants/curve'

class BrushLine extends Object3D {
  constructor (scene, textureInfo, color) {
    super()
    this.reset()
    this.scene = scene
    this.curveControl = true
    this.cachePoints = true
    this.geometry = new BufferGeometry()
    this.material = brushMaterial(textureInfo, color)

    this.line = new Mesh(this.geometry, this.material)
    // this.line.frustumCulled = false
    // this.frustumCulled = false
    this.add(this.line)
  }

  computePoint (mouse) {
    let point = new Vector3(mouse.x, mouse.z, 0)

    if (!this.points.length)
      return this.points.push(point)

    if (this.popAtNextPoint && this.points.length > 2) {
      this.points.pop()
      this.popAtNextPoint = false
    }

    const previous = last(this.points)
    const diff = point.clone().sub(previous)
    const length = diff.length()

    if (length < 0.1 && this.curveControl) return
    // if (length < 0.05) return
    // if (length < 0.5) this.popAtNextPoint = true
    // if (length < 0.3) return

    let angle = Math.atan2(-diff.y, diff.x)

    const limitAngle = Math.PI * (this.curveControl ? .1 : .15)
    const limitAngle2 = Math.PI * .3

    if (this.lastAngle && this.curveControl) {
      const angleDiff = math.shortestAngle(this.lastAngle, angle)

      if (Math.abs(angleDiff) > limitAngle2) return false

      if (Math.abs(angleDiff) > limitAngle) {
        const newAngleOffset = limitAngle * math.sign(angleDiff)
        const newAngle = this.lastAngle - newAngleOffset
        // const newLength = Math.min(Math.abs(diff.x), Math.abs(diff.y)) * Math.cos(newAngleOffset)
        // const newLength = Math.min(Math.abs(diff.x), Math.abs(diff.y))
        const newLength = Math.min((limitAngle / Math.abs(angleDiff)), .3) * length

        const offset = new Vector3(
          Math.cos(newAngle),
          -Math.sin(newAngle),
          0
        )

        angle = newAngle
        point = previous.clone().add(offset.multiplyScalar(newLength))
      }
    }

    this.lastAngle = angle
    this.points.push(point)
    return true
  }

  addPoint (mouse, force) {
    const valid = this.computePoint(mouse)
    if (valid === false && !force) return false // Create new line

    if (this.points.length < 3) return true

    const data = this.computePoints()

    this.setPoints(data)
    if (this.material.wireframe) this.geometry._attributes.index.version = data.points.length // force wireframe update
    this.scene.needsRender = true

    return true
  }

  getLength () {
    return this.material.uniforms.lineLength.value
  }

  drawPath (path, index = 0, total = 1, animate = true) {
    if (!detect.mobile) this.material.uniforms.lineWidth.value *= 1.5
    this.setPoints(this.computePath(path))
    const duration = Math.min(this.getLength() * 20, 1500)
    const delay = 150 * (total === 1 ? 0 : Math.random())
    this.material.uniforms.progress.value = 0
    if (animate) return this.animatePath({ duration, delay })
  }

  animatePath ({ duration = 800, delay = 0, reverse = false, easing = 'easeOutQuad' }) {
    this.animating = true

    const value = reverse ? 0 : [0, 1]

    const { progress } = this.material.uniforms

    return anime({
      targets: progress,
      value,
      duration,
      delay,
      easing,
      update: () => { this.scene.needsRender = true }
    }).finished.then(() => {
      this.animating = false
    })
  }

  reverseLine () {
    const duration = Math.min(this.getLength() * 10, 1500)
    return this.animatePath({ duration, easing: 'easeInOutSine', reverse: true })
  }

  setPoints ({ points, length, normals }) {
    this.material.uniforms.lineLength.value = length
    updateGeometry(this.geometry, points, length, normals, this.material.uniforms.lineWidth.value)
    // computeUvs(this.geometry, length)
    // computeNormals(this.geometry, normals, this.material.uniforms.lineWidth.value)
  }

  computePoints () {
    const curve = convertToCurve(this.points, { pointByUnit: this.pointByUnit })
    const length = this.saved.length + curve.length
    const points = this.saved.points.concat(curve.points)
    const normals = this.saved.normals.concat(curve.normals)

    // save
    const saveSize = 3
    const savePercent = .75

    if (curve.length > saveSize && this.cachePoints) {
      const pointsToSave = Math.round(this.points.length * savePercent)
      const toSave = this.points.splice(0, pointsToSave)
      toSave.push(this.points[0])

      const saved = convertToCurve(toSave, { removeLast: true, pointByUnit: this.pointByUnit })
      this.saved.normals = this.saved.normals.concat(saved.normals)
      this.saved.points = this.saved.points.concat(saved.points)
      this.saved.length += saved.length
    }

    return { points, length, normals }
  }

  reset () {
    this.points = []
    this.direction = 0
    this.previousAngle = false
    this.saved = { points: [], normals: [], length: 0 }
  }

  computePath (data) {
    this.reset()
    this.points = data.rawPoints
    return data
  }

  beforeUpdate () {
    this.material.uniforms.noise.value = this.scene.noise
  }

  destroy () {
    this.remove(this.line)
    this.geometry.dispose()
    this.material.dispose()
    this.points = null
    this.saved = null
  }
}

export default BrushLine
