import { defer, each, map, invokeMap } from 'lodash-es'
import anime from 'animejs'

import Reveal from 'modules/library/reveal/Reveal'
import scroll from 'core/scroll'
import promise from 'helpers/promise'
import Header from 'modules/header/Header'
import background from 'modules/background/background'
import Epigraph from 'modules/epigraph/Epigraph'
import Player from 'modules/player/Player'
import WipeBlock from 'modules/blocks/wipe-block/WipeBlock'
import ParralaxBlock from 'modules/blocks/parralax-block/ParralaxBlock'
import TextReveal from 'modules/library/text-reveal/TextReveal'
import LottieRoll from 'modules/library/lottie-roll/LottieRoll'

import router from '../core/router'

import Page from './Page'

const defaultModuleMap = {
  '.akira-epigraph': Epigraph,
  '.epigraph': Epigraph,
  '.player': Player,
  '.text-block': TextReveal,
  '.wipe-block': WipeBlock,
  '.fade-block': Reveal,
  '.translate-block': Reveal,
  '.lottie-roll': LottieRoll,
  '.img-block': Reveal,
  '.parralax-block': ParralaxBlock
}

export default class SuperPage extends Page {
  imagesToLoad = []
  colors = []

  constructor (el, options = {}) {
    super(...arguments)
    this.moduleMap = Object.assign({}, defaultModuleMap, options.moduleMap || {})
    this.inner = this.el.querySelector('.page__inner')
    const headerDiv = this.el.querySelector('.header, .akira-header')
    if (headerDiv) this.header = new Header(headerDiv)

    this.backToTop = this.el.querySelector('.footer__back-to-top')
    if (this.backToTop) this.backToTop.addEventListener('click', this.goBackToTop)
  }

  prepare (previousPage) {
    super.prepare(...arguments)
  }

  goToPreviousPage = (event) => {
    event.preventDefault()
    if (router.lastPage()) router.navigate(router.lastPage())
    else router.navigate(event.currentTarget.getAttribute('href'))
  }

  goBackToTop = (event) => {
    event.preventDefault()
    scroll.scrollTo(0, 800)
  }

  show (previousPage) {
    this.el.classList.add('top')
    this.el.classList.add('appearing')
    this.resize()

    scroll.lock()

    background.overlapAnimation(this.colors).then(() => {
      this.el.classList.remove('top')
      this.header.enabled = true
    })

    return anime({
      targets: this.el,
      opacity: [0, 1],
      ease: 'easeOutQuad',
      duration: 600,
      delay: 600,
      changeBegin: () => {
        setTimeout(() => this.invokeShow(), 300)
      },
      complete: () => {
        scroll.unlock()
        this.el.classList.remove('appearing')
        document.scrollingElement.scrollTop = 0
        defer(() => {
          background.addScrollLine()
          this.resize()
        })
      }
    }).finished
  }

  invokeShow () {
    invokeMap(this.modules, 'show')
  }

  onLineUpdate = (length) => {
    const progress = Math.min(1, length / 15)
    this.el.style.opacity = progress
  }

  preload () {}

  askShow = previousPage => {
    return this.preloadImages()
      .then(() => this.preload())
      .then(() => { this.onImageLoad() })
      .then(() => promise.wait(previousPage && !this.imagesToLoad ? 0 : 100))
      .then(() => this.show(previousPage))
      .then(() => {
        invokeMap(this.modules, 'shown')
      })
  }

  preloadImages () {
    document.body.style.cursor = 'wait'
    if (!this.imagesToLoad || !this.imagesToLoad.length) return Promise.resolve()
    return Promise.all(map(this.imagesToLoad, this.loadImage))
  }

  loadImage = img => new Promise(resolve => {
    if (img.naturalWidth !== 0) return resolve()
    img.onload = () => {
      img.onload = null
      resolve()
    }
  })

  onImageLoad (callback, previousPage) {
    document.body.style.cursor = 'inherit'
    this.createModules()
  }

  createModules () {
    this.modules = [this.header]
    if (!this.moduleMap) return

    each(this.moduleMap, (Module, selector) => {
      this.modules.push(...map(
        this.el.querySelectorAll(selector),
        el => new Module(el)
      ))
    })
  }

  hide (nextPage) {
    if (nextPage === 'Intro') {
      return anime({
        targets: this.el,
        opacity: [1, 0],
        ease: 'easeInOutQuad',
        duration: 700
      }).finished
    }

    return Promise.resolve()
  }

  flush () {
    invokeMap(this.modules, 'flush')
    if (this.backToTop) this.backToTop.removeEventListener('click', this.goBackToTop)
    super.flush()
  }

  resize () {
    invokeMap(this.modules, 'resize')
    super.resize()
  }
}
