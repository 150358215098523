import { defer, invokeMap } from 'lodash-es'
import anime from 'animejs'

import style from 'core/style'
import SuperPage from 'navigation/SuperPage'
import background from 'modules/background/background'
import Cursor from 'modules/cursor/Cursor'
import Carousel from 'modules/library/carousel/Carousel'

export default class Intro extends SuperPage {
  colors= [style.light]

  constructor (el, options = {}) {
    super(...arguments)
  }

  prepare (previousPage) {
    super.prepare(...arguments)
    this.getElements()
    this.instanceComponents()
    this.toggleEvents(true)
  }

  getElements () {
    this.intro = this.el.querySelector('.intro__block')

    this.landing = this.el.querySelector('.intro__landing')
    this.landingButton = this.landing.querySelector('.landing__button')

    this.manifesto = this.el.querySelector('.manifesto')
    this.manifestoInner = this.manifesto.querySelector('.manifesto__inner')
    this.manifestoCounter = this.manifesto.querySelector('.manifesto__counter')
    this.manifestoButton = this.manifesto.querySelector('.manifesto__close')
  }

  instanceComponents () {
    this.carousel = new Carousel(this.manifestoInner)
    this.cursor = new Cursor(this.el)
  }

  toggleEvents (add = true) {
    const emitMethod = add ? 'on' : 'off'
    const emitMethod2 = add ? 'once' : 'off'
    const method = add ? 'addEventListener' : 'removeEventListener'

    background[emitMethod]('update', this.onBackgroundUpdate)
    background[emitMethod2]('paintReady', this.onStartLanding)
    this.carousel[emitMethod]('update', this.onCarouselUpdate)
    this.landingButton[method]('click', this.onButtonClick)
    this.manifestoButton[method]('click', this.closeForeground)
  }

  onBackgroundUpdate = length => {
    const progress = Math.min(1, length / 15)
    this.landing.style.opacity = progress
  }

  onStartLanding = () => {
    background.off('update', this.onStartLanding)
    this.updateColor(style.white)
    this.intro.classList.add('unclickable')
    this.landing.classList.add('visible')
    this.cursor.discard()

    background.current().animateBackground().then(() => {
      this.landing.classList.remove('unclickable')
    })
  }

  onButtonClick = event => {
    defer(() => {
      this.cursor.discard()
      this.updateColor(style.red)
      this.manifesto.classList.add('visible')
      this.landing.classList.add('unclickable')

      background.openManifesto().then(() => {
        this.manifesto.classList.remove('unclickable')
      })
    })
  }

  updateColor (color) {
    this.el.style.setProperty('--header-color', color)
    this.el.style.setProperty('--text-color', color)
  }

  onCarouselUpdate = (step) => {
    this.manifestoCounter.innerText = `${step + 1} / 2`
    this.manifesto.style.setProperty('--progress', (step + 1) / 2)
  }

  closeForeground = () => {
    this.cursor.discard()
    this.updateColor(style.white)
    this.manifesto.classList.remove('visible')
    this.manifesto.classList.add('unclickable')
    background.closeManifesto().then(() => {
      this.landing.classList.remove('unclickable')
    })
  }

  show (previousPage) {
    this.resize()

    background.drawable = true

    if (previousPage)
      background.removeLines()

    return anime({
      targets: this.el,
      opacity: [0, 1],
      duration: 700,
      changeBegin: () => {
        setTimeout(() => this.invokeShow(), 300)
      },
      easing: 'easeOutQuad'
    }).finished
  }

  hide () {
    background.drawable = false
    super.hide(...arguments)
  }

  resize () {
    const tablet = window.matchMedia('(max-width: 1024px)')
    if (tablet) this.carousel.enable()
    else this.carousel.disable()
    this.cursor.resize()
    super.resize()
  }

  flush () {
    super.flush()
    this.cursor.flush()
    this.carousel.flush()
    this.toggleEvents(false)
  }
}

Intro.pageName = 'Intro'
