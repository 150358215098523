import raf from '@internet/raf'
import Inrtia from 'inrtia'

import browser from 'helpers/browser'
import detect from 'helpers/detect'

export default class ImageRoll {
  constructor (el) {
    if (detect.touch) return
    this.el = el

    this.inrtia = new Inrtia({
      value: { x: 0, y: 0 },
      precisionStop: 0.1,
      perfectStop: true,
      friction: 10
    })

    this.getElements()
    this.toggleEvents(true)
  }

  getElements () {
    this.image = this.el.querySelector('.image-roll__img-wrapper')
  }

  toggleEvents (add = true) {
    if (!this.el) return
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.el[method]('mouseover', this.mouseover)
    this.el[method]('mousemove', this.mousemove)
    raf[add ? 'add' : 'remove'](this.update)
  }

  mouseover = (event) => {
    event = browser.mouseEvent(event)
    this.inrtia.value.x = event.clientX
    this.inrtia.value.y = event.clientY
  }

  mousemove = (event) => {
    this.lastEvent = browser.mouseEvent(event)
  }

  update = () => {
    if (!this.lastEvent) return

    const position = { x: this.lastEvent.clientX, y: this.lastEvent.clientY }
    this.inrtia.to(position)

    this.updateTransform()
  }

  updateTransform = (force = false) => {
    if (this.inrtia.stopped && force !== true) return
    const value = this.inrtia.update()
    this.image.style.setProperty('--translate', `translate3d(${value.x}px, ${value.y}px, 0)`)
  }

  flush () {
    this.toggleEvents(false)
  }

  resize () {}
}
