import { Object3D, Vector3 } from 'three'
import Inrtia from 'inrtia'
import { sample } from 'lodash-es'

import scroll from 'core/scroll'
import resize from 'helpers/resize'
import paths from 'canvas/constants/paths'
import detect from 'helpers/detect'
import browser from 'helpers/browser'
import background from 'modules/background/background'
import style from 'core/style'
import color from 'helpers/color'

import BrushLine from './BrushLine'

class BrushLines extends Object3D {
  constructor (scene) {
    super()
    this.scene = scene
    this.animated = 0

    this.lines = []
    this.currentLine = null

    this.inrtia = new Inrtia({
      value: { x: 0, y: 0 },
      precisionStop: 0.1,
      perfectStop: false,
      friction: 3
    })

    this.toggleEventListener()
  }

  createLine (textureInfo, color) {
    const line = new BrushLine(this.scene, textureInfo, color || this.scene.color)
    line.renderOrder = this.lines.length
    this.lines.push(line)
    this.add(line)
    return line
  }

  drawPaths (paths, textureInfo, color) {
    this.animated++

    return Promise.all(paths.map((path, i) => {
      const line = this.createLine(textureInfo, color)
      return line.drawPath(path, i, paths.length)
    })).then(() => {
      this.animated = Math.max(0, this.animated - 1)
      this.snapshot()
    })
  }

  toggleEventListener (add = true) {
    const method2 = add ? 'on' : 'off'
    const method = add ? 'addEventListener' : 'removeEventListener'
    document.body[method](detect.touch ? 'touchstart' : 'mousedown', this.mouseDown, { passive: false })
    document.body[method](detect.touch ? 'touchend' : 'mouseup', this.mouseUp, { passive: false })
    document.body[method](detect.touch ? 'touchmove' : 'mousemove', this.mouseMove, { passive: false })
    scroll.instance()[method2](this.scroll)
  }

  setMouse (event, force) {
    event = browser.mouseEvent(event)

    const value = {
      x: (event.clientX / resize.width()) * 2 - 1,
      y: -(event.clientY / resize.height()) * 2 + 1
    }

    this.dirty = true

    if (force)
      return (this.inrtia.value = value)

    this.inrtia.to(value)
  }

  scroll = () => {
    if (this.scene.locked || !this.scrollLine) return
    const progress = Math.max(scroll.scrollTop() / this.scrollHeight, 0)

    // this.scrollLine.visible = progress > 0
    this.scrollLine.material.uniforms.progress.value = progress
  }

  addScrollLine () {
    this.resize()

    const { color: _color } = this.scene
    const offset = _color === style.brown ? -0.06 : -0.06
    const darken = color.luminosity(_color, offset)
    this.scrollLine = this.createLine(null, darken)
    this.scrollLine.animating = true
    this.scrollLine.drawPath(sample(paths.scroll), 0, 1, false)
  }

  mouseDown = event => {
    if (this.scene.locked || !background.drawable) return
    this.currentLine = this.createLine()
    this.setMouse(event, true)
    this.addPoint(this.currentLine)
  }

  mouseMove = event => {
    if (this.scene.locked || !background.drawable) return
    if (detect.touch) event.preventDefault()
    if (this.currentLine) this.setMouse(event)
  }

  mouseUp = event => {
    if (!this.currentLine) return
    this.setMouse(event)
    this.addPoint(this.currentLine)
    this.currentLine = null
    this.snapshot()
    this.checkLength()
  }

  getTotalLength (noAnimated = true) {
    return this.lines.reduce((memo, l) => {
      if (l.animated && noAnimated) return memo
      return memo + l.getLength()
    }, 0)
  }

  checkLength () {
    if (this.animated) return
    const totalLength = this.getTotalLength()

    if (totalLength > 15)
      this.scene.emit('paintReady')
  }

  triggerAnimation (color, pathId = 0, options = {}) {
    // return this.drawPaths(paths.lines[pathId], textures.brushes[2], color)
    return this.drawPaths(paths.lines[pathId], null, color)
  }

  removeLines (direct) {
    return Promise.all(this.lines.map(l => {
      return (direct ? Promise.resolve() : l.reverseLine())
        .then(() => this.remove(l))
        .then(() => l.destroy())
    })).then(() => {
      this.lines = []
      this.animated = 0
      this.snapshot()
    })
  }

  snapshot () {
    if (!this.animated) this.scene.cache()
  }

  addPoint (line) {
    if (this.inrtia.stopped) return

    const value = this.inrtia.update()
    const mouse = new Vector3(value.x, value.y, 0)

    mouse.unproject(this.scene.getCamera())
    const valid = line.addPoint(mouse)

    if (!valid) this.splitCurrentLine(line, mouse)

    this.dirty = false
    this.scene.emit('update', this.getTotalLength())
  }

  splitCurrentLine (line, mouse) {
    this.currentLine = null
    this.snapshot()
    this.currentLine = this.createLine()
    const last = line.points[line.points.length - 1]
    this.currentLine.addPoint(new Vector3(last.x, 0, last.y))
    this.currentLine.addPoint(mouse)
  }

  draw () {
    if (this.currentLine) this.addPoint(this.currentLine)
  }

  beforeUpdate () {
    this.draw()
    this.lines.forEach((l) => l.beforeUpdate())
  }

  shouldCache (l) {
    return !l.animating && l !== this.currentLine && l !== this.scrollLine
  }

  prepareSnapshot () {
    this.lines.forEach((l) => {
      l.visible = this.shouldCache(l)
      l.material.uniforms.noiseMultiplier.value = 0
    })
  }

  clearAfterSnapshot () {
    this.lines.forEach((l) => {
      l.material.uniforms.noiseMultiplier.value = 1
      l.visible = !this.shouldCache(l)
    })
  }

  resize () {
    this.scrollHeight = Math.max(document.scrollingElement.scrollHeight - resize.height(), resize.height())
  }

  destroy () {
    this.lines.forEach((l) => l.destroy())
    this.lines = null
    this.toggleEventListener(false)
  }
}

export default BrushLines
