const int = (color) => parseInt(color.replace('#', '0x'), 16)
const hex = (x) => ('0' + parseInt(x).toString(16)).slice(-2)
const rgbToHex = (rgb) => {
  if (~rgb.indexOf('#')) return rgb
  rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/)
  return '#' + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3])
}

function luminosity (color, percent) {
  const f = parseInt(color.slice(1), 16), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent, R = f >> 16, G = f >> 8 & 0x00FF, B = f & 0x0000FF
  return '#' + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1)
}

const color = { int, rgbToHex, hex, luminosity }
export default color
