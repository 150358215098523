
import TextAnimator from '../text-animator/TextAnimator'
import Reveal from '../reveal/Reveal'

export default class TextReveal extends Reveal {
  constructor (el, textOptions, progress) {
    super(el, progress)
    this.title = new TextAnimator(el, textOptions)
  }

  onAppear () {
    this.title.animateIn()
  }

  onDisappeared () {
    this.title.animateOut(0, false)
  }

  flush () {
    super.flush()
    this.title.flush()
    this.title = null
  }
}
