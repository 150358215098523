import { BufferAttribute, CatmullRomCurve3, Vector3 } from 'three'
import getNormals from 'polyline-normals'
import simplify from 'simplify-js'

const updateGeometry = (geometry, points, length, normals, lineWidth) => {
  const indices = []
  const positions = []
  const uvs = []

  if (points.length && points[0] instanceof Vector3) {
    for (let i = 0; i < points.length; i++) {
      const p = points[i]
      positions.push(p.x, p.y, p.z)
      positions.push(p.x, p.y, p.z)
    }
  } else {
    for (let i = 0; i < points.length; i += 3) {
      positions.push(points[i], points[i + 1], points[i + 2])
      positions.push(points[i], points[i + 1], points[i + 2])
    }
  }

  const l = positions.length / 6
  const w = lineWidth / 2, mitter = 1

  let distance = 0, previousX, previousZ

  for (let j = 0; j < l; j++) {
    // uvs
    const k = j * 6

    if (j > 0) {
      const x = positions[k]
      const z = positions[k + 2]

      distance += Math.hypot(previousX - x, previousZ - z) / length
      uvs.push(distance, 0)
      uvs.push(distance, 1)
    } else {
      uvs.push(0, 0)
      uvs.push(0, 1)
    }

    previousX = positions[k]
    previousZ = positions[k + 2]

    // normals
    const normal = normals[j][0]
    positions[k] += mitter * w * normal[0]
    positions[k + 2] += mitter * w * normal[1]
    positions[k + 3] += mitter * -w * normal[0]
    positions[k + 5] += mitter * -w * normal[1]

    // indices
    if (j < l - 1) {
      const n = j * 2
      indices.push(n + 2, n + 1, n)
      indices.push(n + 3, n + 1, n + 2)
    }
  }

  geometry.positions = new Float32Array(positions)
  geometry.uvs = new Float32Array(uvs)
  geometry.indices = new Uint16Array(indices)

  geometry._attributes = {
    index: new BufferAttribute(geometry.indices, 1),
    uv: new BufferAttribute(geometry.uvs, 2),
    position: new BufferAttribute(geometry.positions, 3)
  }

  geometry.setIndex(geometry._attributes.index)
  geometry.setAttribute('uv', geometry._attributes.uv)
  geometry.setAttribute('position', geometry._attributes.position)
  geometry.computeBoundingSphere()
  geometry.computeBoundingBox()
}

const convertPath = (path, { pointByUnit = 4, size = 10, svgSize = 100, pointByUnitCurve = 8, offset, simplifyPath = true } = {}) => {
  const pathLength = path.getTotalLength()
  const scale = size / svgSize
  const length = pathLength * scale * pointByUnit

  const points = []

  for (let i = 0; i < length; i++) {
    const point = path.getPointAtLength(i / length * pathLength)
    const newPoint = new Vector3(point.x * scale - size / 2, point.y * scale - size / 2, 0)
    if (offset) {
      newPoint.x += offset.x
      newPoint.y += offset.y
    }
    points.push(newPoint)
  }

  return convertToCurve(points, { pointByUnit: pointByUnitCurve, simplifyPath })
}

const convertToCurve = (points, { pointByUnit = 8, removeLast = false, simplifyPath = false } = {}) => {
  // points = simplify(points, .01, true)
  const curve = new CatmullRomCurve3(points, false, 'chordal')
  // curve.arcLengthDivisions = 500
  // curve.updateArcLengths()
  const curveLength = curve.getLength()
  let totalPoints = Math.ceil(Math.max(1, curveLength * pointByUnit))

  let smoothedPoints = curve.getPoints(totalPoints)
  if (simplifyPath) smoothedPoints = simplify(smoothedPoints, .02, true)
  // const smoothedPoints = curve.getPoints(totalPoints)
  // const tangeants = curve.getTangentAt(totalPoints)

  const normalPoints = []
  const formatted = []
  let length = 0, p

  totalPoints = smoothedPoints.length - 1

  const l = totalPoints + (removeLast ? -1 : 0)

  for (let i = 0; i <= totalPoints; i++) {
    p = smoothedPoints[i]
    if (i !== 0) length += p.distanceTo(smoothedPoints[i - 1])
    if (i <= l) {
      formatted.push(p.x, 0, p.y)
      normalPoints.push([p.x, p.y])
    }
  }

  const normals = getNormals(normalPoints, false)

  return {
    rawPoints: points,
    points: formatted,
    normals,
    length
  }

  // return [formatted, length]
}

const getCurveLength = (points, scale = 1) => {
  let length = 0
  const l = points.length

  for (let i = 3; i < l; i += 3) {
    const x1 = points[i - 3]
    const z1 = points[i - 1]
    const x2 = points[i]
    const z2 = points[i + 2]

    length += Math.hypot(x1 - x2, z1 - z2) / scale
  }

  return length
}

export { convertToCurve, convertPath, getCurveLength, updateGeometry }
