
export default class Player {
  constructor (el) {
    this.el = el
    this.player = el.querySelector('video')
    this.toggleEvents()
  }

  toggleEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.el[method]('click', this.click)
    this.player[method]('play', this.statusUpdate)
    this.player[method]('pause', this.statusUpdate)
    this.player[method]('ended', this.statusUpdate)
  }

  statusUpdate = (event) => {
    this.el.classList.toggle('playing', !this.player.paused)
  }

  click = (event) => {
    if (this.player.paused) this.player.play()
    else this.player.pause()
  }

  flush () {
    this.toggleEvents(false)
  }

  resize () {
  }
}
